import React from 'react'

import './dtGallery.scss'

export const Gallery = ({ folder, smallImages = [], largeImages = [] }) => {
  return (
    <div className='dtGalleryContainer container'>
      <div className='dtGallery'>
        {smallImages.map(image => (
          <img className='dtGallery-image' key={image} src={`/images/cases-details/${folder}/${image}`} alt='' />
        ))}
        {largeImages.map(image => (
          <img className='dtGallery-image __large' key={image} src={`/images/cases-details/${folder}/${image}`} alt='' />
        ))}
      </div>
    </div>
  )
}
