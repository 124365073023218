import React, { useContext, useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx'
import { useLocation, Link, useHistory } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import { Context } from '../../context'
import { Gallery } from './gallery'
import { classNames } from '../utils/class-names'

import './details.scss'
import './dtPromo.scss'
import './dtInfo.scss'
import './dtNext.scss'
import './dtContent.scss'

export const Details = ({ isShow }) => {
  const { hash } = useLocation()
  const history = useHistory()
  const detailsRef = useRef(null)
  const [screen, setScreens] = useState(null)
  const [nextScreen, setNextScreens] = useState(null)
  const { screens } = useContext(Context)
  const rootRef = useRef(null)

  useEffect(() => {
    if (!isShow) return

    const index = screens.findIndex(it => it.link === hash.replace('#', ''))
    if (index === -1) {
      history.push(`/case#${screens[1].link}`)
      return
    }
    const nextIndex = index === screens.length - 1 ? 1 : index + 1
    setScreens(screens[index])
    setNextScreens(screens[nextIndex])
  }, [hash, screens, history, isShow])

  useEffect(() => {
    if (!rootRef.current) {
      rootRef.current = document.querySelector('#root')
    }
    rootRef.current && rootRef.current.scrollTo(0, 0)
  }, [screen])

  return (
    <CSSTransition
      in={isShow}
      timeout={500}
      classNames={classNames}
      unmountOnExit
    >
      <section ref={detailsRef} className='details'>
        {screen &&
          <>
            <div
              className='dtPromo'
              style={{
                backgroundImage: `url(/images/cases-details/${screen.link}/${screen.page.image})`,
                backgroundColor: screen.bgcolor
              }}
            >
              <Link to={`/cases#${screen.link}`} className='dtPromo-close'>Закрыть</Link>
              <p className='dtPromo-event'>
                <span className='ru'>{screen.eventType.ru}</span>
                <span className='en'>{screen.eventType.en || screen.eventType.ru}</span>
              </p>
              <h2 className='dtPromo-title'>
                <span className='ru'>{ReactHtmlParser(screen.title.ru)}</span>
                <span className='en'>{ReactHtmlParser(screen.title.en || screen.title.ru)}</span>
              </h2>
            </div>

            <div
              className='dtInfo'
              style={{ backgroundColor: screen.color }}
            >
              <div className='dtInfo-Container container'>
                <div className='dtInfo-textWrap'>
                  <div className='dtInfo-metaWrap'>
                    <ul className='dtInfo-metaList'>
                      {screen.page.meta.date.ru &&
                        <li className='dtInfo-metaItem __date'>
                          <span className='ru'>{screen.page.meta.date.ru}</span>
                          <span className='en'>{screen.page.meta.date.en || screen.page.meta.date.ru}</span>
                        </li>}
                      {screen.page.meta.location.ru &&
                        <li className='dtInfo-metaItem __location'>
                          <span className='ru'>{screen.page.meta.location.ru}</span>
                          <span className='en'>{screen.page.meta.location.en || screen.page.meta.location.ru}</span>
                        </li>}
                      {screen.page.meta.events.map((event, i) => (
                        <li key={i} className={clsx('dtInfo-metaItem __event', event.type && `__${event.type}`, !event.type && '__normal')}>
                          <span className='ru'>{event.ru}</span>
                          <span className='en'>{event.en || event.ru}</span>
                        </li>))}
                      {!!screen.page.meta.tags.length &&
                        <li className='dtInfo-metaItem __tags'>
                          {screen.page.meta.tags.length.length > 1 ? 'Хештеги' : 'Хештег'} события:
                          {screen.page.meta.tags.map((tag, i) => (
                            <span key={i} className='dtInfo-metaHashtag'>{tag}</span>
                          ))}
                        </li>}
                    </ul>
                  </div>
                  <p className='dtInfo-infoText'>
                    <span className='ru'>{ReactHtmlParser(screen.page.intro.ru)}</span>
                    <span className='en'>{ReactHtmlParser(screen.page.intro.en || screen.page.intro.ru)}</span>
                  </p>
                </div>
                <div className='dtInfo-video'>
                  {screen.page.video &&
                    <div className='dtInfo-videoInner'>
                      <iframe className='dtInfo-videoIframe' title={screen.title.ru} src={screen.page.video} height='640' width='0' frameBorder='0' allowFullScreen='1' />
                    </div>}
                  {screen.page.videoImage && <img className='dtInfo-videoImage' src={`/images/cases-details/${screen.link}/${screen.page.videoImage}`} alt='' />}
                </div>
              </div>
            </div>

            <div className='dtContent __indent'>
              <div className='dtContent-container container'>
                <div className='dtContent-inner'>
                  {screen.page.content.image && <img className={clsx('dtContent-image', screen.page.content.imageSmall && '__small')} src={`/images/cases-details/${screen.link}/${screen.page.content.image}`} alt='' />}
                  {screen.page.content.imageText &&
                    <div className='dtContent-imageText' style={{ color: screen.color }}>
                      {ReactHtmlParser(screen.page.content.imageText.ru)}{ReactHtmlParser(screen.page.content.imageText.en)}
                    </div>}
                  <div className='dtContent-text' style={{ fontSize: `${screen.page.content.text.fz}pt` }}>
                    {ReactHtmlParser(screen.page.content.text.ru)}{ReactHtmlParser(screen.page.content.text.en)}
                  </div>
                </div>
              </div>
            </div>

            {screen.page.gallery && <Gallery folder={screen.link} smallImages={screen.page.gallery.small} largeImages={screen.page.gallery.large} />}

            {screen.page.content2 &&
              <div className='dtContent'>
                <div className='dtContent-container container'>
                  <div className='dtContent-inner'>
                    <div className='dtContent-textLeft __bold __title' style={{ color: screen.color, fontSize: `${screen.page.content2.fzTitle}pt` }}>
                      {ReactHtmlParser(screen.page.content2.title)}
                    </div>
                    <div className='dtContent-textRight' style={{ fontSize: `${screen.page.content2.fzText}pt` }}>
                      {ReactHtmlParser(screen.page.content2.text)}
                    </div>
                  </div>
                </div>
              </div>}

            {screen.page.gallery2 && <Gallery folder={screen.link} smallImages={screen.page.gallery2.small} largeImages={screen.page.gallery2.large} />}

            <div className={clsx('dtContentWrap', !screen.page.gallery3 && '__noGallery')} style={{ backgroundImage: screen.page.gradient }}>
              {screen.page.content3 &&
                <div className='dtContent __white'>
                  <div className='dtContent-container container'>
                    <div className='dtContent-inner'>
                      <div className='dtContent-textLeft text-normal' style={{ fontSize: `${screen.page.content3.fzLeft}pt` }}>
                        {ReactHtmlParser(screen.page.content3.left)}
                      </div>
                      <div className='dtContent-textRight' style={{ fontSize: `${screen.page.content3.fzRight}pt` }}>
                        {ReactHtmlParser(screen.page.content3.right)}
                      </div>
                    </div>
                  </div>
                </div>}

              {screen.page.gallery3 && <Gallery folder={screen.link} smallImages={screen.page.gallery3.small} largeImages={screen.page.gallery3.large} />}
            </div>

            <Link to={`/case#${nextScreen.link}`} className='dtNext' style={{ backgroundColor: nextScreen.color }}>
              <div className='dtNext-container container'>
                <p className='dtNext-text'>
                  <span className='ru'>{ReactHtmlParser(nextScreen.title.ru)}</span>
                  <span className='en'>{ReactHtmlParser(nextScreen.title.en)}</span>
                </p>
              </div>
            </Link>
          </>}
      </section>
    </CSSTransition>
  )
}
