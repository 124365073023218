import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useHistory, useLocation } from 'react-router-dom'
import Xwiper from 'xwiper'
import { Context } from '../../context'

import { Form } from '../form'
import { Pagination } from './pagination'
import { Buttons } from './buttons'
import { Case } from './case'

import { setMouseWheelHandler } from '../utils/mouse-wheel'
import { classNames } from '../utils/class-names'

import './cases.scss'

export const Cases = ({ isShow }) => {
  const { hash } = useLocation()
  const history = useHistory()

  const { screens, fnList, global } = useContext(Context)
  const [isShowCase, setShowCase] = useState(false)
  const [current, setCurrent] = useState(0)
  const [screen, setScreen] = useState(screens[current])
  const [isShowForm, setShowForm] = useState(false)

  const casesRef = useRef(null)

  const changeCurrentCase = useCallback(isNext => {
    setCurrent(prev => {
      return isNext
        ? (prev || screens.length) - 1
        : prev === screens.length - 1 ? 0 : prev + 1
    })
  }, [screens.length])

  useEffect(() => {
    fnList.setCurrentScreen = setCurrent
  }, [fnList.setCurrentScreen])

  useEffect(() => {
    const hashName = hash.replace('#', '') || global.caseLink
    const index = screens.findIndex(it => it.link === hashName)
    const normalIndex = index === -1 ? 0 : index
    global.caseLink = hashName
    setCurrent(normalIndex)
    setScreen(screens[normalIndex])
  }, [hash, screens, global.caseLink])

  useEffect(() => {
    if (casesRef.current) {
      const xwiper = new Xwiper(casesRef.current, { threshold: 100 })
      xwiper.onSwipeLeft(() => changeCurrentCase())
      xwiper.onSwipeRight(() => changeCurrentCase(true))
      xwiper.onSwipeUp(() => changeCurrentCase())
      xwiper.onSwipeDown(() => changeCurrentCase(true))

      setMouseWheelHandler(
        casesRef.current,
        changeCurrentCase
      )

      return () => {
        xwiper.destroy()
      }
    }
  }, [changeCurrentCase, isShow])

  useEffect(() => {
    setShowCase(false)
  }, [current])

  useEffect(() => {
    setShowCase(true)
  }, [screen])

  return (
    <CSSTransition
      in={isShow}
      timeout={500}
      classNames={classNames}
      unmountOnExit
    >
      <div className='casesWrap'>
        <div className='cases' ref={casesRef}>
          <div className='cases-inner'>
            <CSSTransition
              in={isShowCase}
              timeout={1200}
              classNames={classNames}
              unmountOnExit
              onExited={() => {
                const currentScreen = screens[current]
                global.caseLink = currentScreen.link
                setScreen(currentScreen)
                history.push(`/cases#${currentScreen.link || 'promo'}`)
              }}
            >
              {screen ? <Case screen={screen} /> : <></>}
            </CSSTransition>

            <Buttons setShowForm={setShowForm} />
            <Pagination current={current} onClickPagination={setCurrent} />
          </div>
        </div>
        <Form isShowForm={isShowForm} setShowForm={setShowForm} />
      </div>
    </CSSTransition>
  )
}
