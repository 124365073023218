export const expertisesData = [
  {
    ru: 'корпоративное мероприятие',
    en: 'corporate event',
    text: {
      ru: 'Наполняем корпоративное событие сюжетной линией и яркими решениями. Именно так рождается истинный режиссерский ивент!',
      en: 'Filling a corporate event with a storyline and vivid solutions. This is how real directed event is born!'
    }
  },
  {
    ru: 'клиентское мероприятие',
    en: 'customer event',
    text: {
      ru: 'Помогаем понять, чем можно впечатлить вашего клиента. Так создается основа для ивента, который не оставит равнодушным ни одного гостя!',
      en: 'We help to understand what could impress your customer. That is how the basis of events in being created, of the event that will touch every guest’s feelings!'
    }
  },
  {
    ru: 'team building',
    en: 'team building',
    text: {
      ru: 'Создаем и реализуем идеи, которые действительно могут сплотить команду и вывести взаимодействие на новый уровень!',
      en: 'We create and realize ideas that will in very deed help to unite a team and take the interaction between its members to a new level!'
    }
  },
  {
    ru: 'презентация',
    en: 'presentation',
    text: {
      ru: 'Усиливаем преимущества вашей компании или продукта в глазах аудитории. Внимание к деталям - ключ к формированию цельного впечатления!',
      en: 'We enforce the advantages of your company or of your product presenting it to the audience. Attention to details is a key to the development of a comprehensive impression!'
    }
  },
  {
    ru: 'family day',
    en: 'family day',
    text: {
      ru: 'Создаем ивенты, которые удивляют и увлекают как детей, так и взрослых. Ведь мы знаем: счастливые глаза семьи – это лучшая награда для сотрудника компании!',
      en: 'We create events that impress and captive children as well as adults. Because we know: happy faces of the family is the best award to a member of any company.'
    }
  },
  {
    ru: 'лонч',
    en: 'launch',
    text: {
      ru: 'Помогаем выпуску на рынок нового продукта, даем начало маркетинговой компании. Яркий старт – залог успеха!',
      en: 'We help to launch a new product to the market, develop the beginning of the marketing campaign. Bright start is a formula of success!'
    }
  },
  {
    ru: 'тест-драйв',
    en: 'test drive',
    text: {
      ru: 'Каждый тест-драйв несет в себе похожую цель. Но если добавить в него эксклюзивную идею и оригинальный контент, то он непременно станет Событием!',
      en: 'Each test drive pursue a similar aim. But if to add there an exclusive idea and original content – it will definitely become an Event!'
    }
  },
  {
    ru: 'конференция',
    en: 'conference',
    text: {
      ru: 'Добавим нестандартные ходы и правильную атмосферу в рамках конференции в любой сфере!',
      en: 'We will add non-typical solutions and right setting within the frames of a conference in any field!'
    }
  },
  {
    ru: 'выставка',
    en: 'exhibition',
    text: {
      ru: 'Чтобы в рамках выставки вас заметили, нужен действительно яркий, нестандартный ход. И это – важнейший шаг в стратегии продвижения!',
      en: 'To be distinguished at the exhibition you need a truly outstanding solution. And this is the crucial stage of the promotional strategy!'
    }
  },
  {
    ru: 'm.i.c.e',
    en: 'm.i.c.e',
    text: {
      ru: 'Знаем, как выбрать правильное место и нужную концепцию для выездных деловых мероприятий!',
      en: 'We know how to pick the right location and concept for the off-site business events!'
    }
  },
  {
    ru: 'спортивное событие',
    en: 'sport event',
    text: {
      ru: 'Поможем организовать настоящий праздник спорта, который сумеет выйти за пределы просто активного отдыха на открытом воздухе!',
      en: 'We will help to arrange an actual sport feast that will be beyond the borders of a regular open-air activities!'
    }
  },
  {
    ru: 'спонсорская интеграция',
    en: 'sponsor integration',
    text: {
      ru: 'Знаем, как органично вплести ваш бренд в любое мероприятие. Это становится возможным благодаря level-up-концепции и пакету ярких, эксклюзивных решений!',
      en: 'We know how to link your brand to an event of any type. This is possible by reason of the level-up concept and package of exclusive solutions!'
    }
  }
]
