import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import { Header } from '../header'
import { Promo } from '../promo'
import { Pages } from '../pages'

import { Context } from '../../context'
import { screens } from '../../data/screens'
import { useLang } from '../utils/lang'

const App = () => {
  useLang()

  return (
    <Context.Provider
      value={{
        screens,
        global: {
          caseLink: 'promo'
        },
        fnList: {}
      }}
    >
      <Router>
        <h1 className='visually-hidden'>Panda Project — Агентство режиссерских событий</h1>
        <Switch>
          <Route exact path='/'>
            <Promo />
          </Route>
          <Route>
            <Header />
            <Route exact path='/:page'>
              <Pages />
            </Route>
          </Route>
        </Switch>
      </Router>
    </Context.Provider>
  )
}

export default App
