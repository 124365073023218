export const bridgeFuture = {
  type: 'screen',
  color: '#4760fd',
  eventType: {
    ru: 'Мероприятие с участием высокопоставленных гостей',
    en: 'EVENT WITH VIP GUESTS'
  },
  title: {
    ru: 'Мост в будущее',
    en: 'BRIDGE TO THE FUTURE'
  },
  link: 'bridge-future',
  bgcolor: '#252a49',
  page: {
    image: '031.jpg',
    meta: {
      date: {
        ru: 'август 2016',
        en: 'AUGUST 2016'
      },
      location: {
        ru: 'Санкт-Петербург',
        en: 'SAINT PETERSBURG'
      },
      events: [],
      tags: []
    },
    intro: {
      ru: 'Организация и проведение деловых мероприятий — это очень ответственное дело. Наше сотрудничество с компаниями, строившими и обеспечивающими деятельность «Западного скоростного диаметра», — это история множества важных событий, которые мы помогли создать и ярко выделить.',
      en: 'Arrangement and hosting of business events is very demanding case. Our collaboration with companies, which built and which maintain the work of “Western speed diameter” – is a history of numerous important events that we have helped to develop and vividly distinguish.'
    },
    video: 'https://www.youtube.com/embed/gkyZiLoAH5U?autoplay=0',
    videoImage: null,
    gradient: 'linear-gradient(150deg,#4760fd,#024479)',
    gallery: {
      small: ['IMG_1148.jpg', 'IMG_1159.jpg'],
      large: []
    },
    gallery2: {
      small: ['IMG_1166.jpg', 'IMG_1139.jpg'],
      large: []
    },
    gallery3: {
      small: [],
      large: ['IMG_1165.jpg']
    },
    content: {
      image: 'most.png',
      text: {
        ru: '<p class="ru"><b>Западный скоростной диаметр</b> — тот проект, который меняет к лучшему жизнь каждого петербуржца, целого города и огромной страны. Он всегда в центре внимания, поэтому мы рады обеспечить качество мероприятий с участием важнейших персон, например губернатора Петербурга или премьер-министра Италии.</p>',
        en: '<p class="en"><b>"Western speed diameter"</b> — is such kind project that transforms to the better a life of every Saint Petersburg citizen, of the whole city and wide country. It is always in the limelight, so we are excited to provide the quality of VIP events, with presence of such persons as the governor of Saint Petersburg or prime minister of Italy.</p>',
        fz: '19'
      }
    },
    content2: {
      title: '<p class="ru">Как провести день, который изменит будущее?</p><p class="en">How to spend a day that will change the future?</p>',
      fzTitle: '31',
      text: '<p class="txt-light ru">В день смыкания контура Центрального участка город на Неве ждал самых почетных гостей: первых лиц России и Турции. И мы благодарны, что множеством предыдущих мероприятий заслужили право на организацию этого важного события.</p><p class="txt-light en">On the day of the interlocking of the Central section, Saint Petersburg has been awaiting of very special guests: top officials of Russia and Turkey. And we are grateful that we have earned the right to arrange this key ceremony by hosting large number of previous events.</p><p class="text-normal ru">Для нас очень важно прикоснуться к истории, стать участниками дня, который запомнится на долгие годы и изменит жизнь каждого, кто любит Петербург.</p><p class="text-normal en">It is essential for us to adjoin to the history, to become a part of the day which will be remembered for years and will change lives of everyone who loves Saint Petersburg.</p>',
      fzText: '19'
    },
    content3: {
      left: '<p class="ru">Вера Дерюгина:</p><p class="en">Vera Deriugina:</p><p class="ru text-uppercase txt-size-10">менеджер по связям<br /><br />с общественностью<br /><br />ICA Construction</p><p class="en text-uppercase txt-size-10">PUBLIC RELATIONS MANAGER OF ICA CONSTRUCTION</p><p class="block-line bottom-underline"></p>',
      fzLeft: '19',
      right: '<p class="ru">«Статусные мероприятия — это всегда повышенная ответственность. Все должно быть просчитано до мелочей, и именно в этом заключается профессионализм агентства. Panda Project — это олицетворение надежности, поэтому работа с ними — это в первую очередь комфорт и уверенность!»</p><p class="en">"High-profile events arrangement is always a higher responsibility. Everything shall be figured out precisely and this exact quality is a sign of professionalism of the agency. Panda Project is an embodiment of reliability, so work with them is, first of all - comfort and confidence!"</p>',
      fzRight: '19'
    }
  }
}
