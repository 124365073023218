import React from 'react'
import { useParams } from 'react-router-dom'

import { Cases } from '../cases'
import { Details } from '../details'
import { Experience } from '../experience'
import { Contacts } from '../contacts'

export const Pages = () => {
  const { page } = useParams()

  return (
    <>
      <Cases isShow={page === 'cases'} />
      <Details isShow={page === 'case'} />
      <Experience isShow={page === 'experience'} />
      <Contacts isShow={page === 'contacts'} />
    </>
  )
}
