import clsx from 'clsx'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'

import { useHistory, useLocation } from 'react-router-dom'
import { setMouseWheelHandler } from '../utils/mouse-wheel'
import './promo.scss'

export const Promo = () => {
  const history = useHistory()
  const { hash } = useLocation()

  const [isShow, setShow] = useState(true)
  const [isReady, setReady] = useState(false)
  const [isFlash, setFlash] = useState(false)
  const linkRef = useRef(null)
  const textBottom = useRef(null)

  const handleTextBottomTransitionend = useCallback(() => {
    setFlash(true)
    history.push('/#splash')
  }, [history])

  const handleLinkClick = evt => {
    evt.preventDefault()
    setShow(false)
  }

  useEffect(() => {
    setReady(true)
    const textLast = textBottom.current
    textLast.addEventListener('transitionend', handleTextBottomTransitionend)
    window.innerWidth < 1024 && handleTextBottomTransitionend()
    setMouseWheelHandler(linkRef.current, () => {
      setShow(false)
    })

    return () => {
      textLast.removeEventListener('transitionend', handleTextBottomTransitionend)
    }
  }, [history, hash, handleTextBottomTransitionend])

  return (
    <CSSTransition
      in={isShow} timeout={1350}
      classNames={{
        exitActive: '__activeExit',
        exitDone: '__doneExit'
      }}
      onExited={() => {
        history.push('/cases#promo')
      }}
    >
      <section className={clsx(
        'promo',
        isReady && '__active',
        isFlash && '__flash',
        !isFlash && hash === '#splash' && '__splash')}
      >
        <h2 className='visually-hidden'> 123</h2>

        <p className='promo-text __top'>
          <span className='ru'>Живая</span>
          <span className='en'>Live</span>
        </p>
        <p className='promo-text __middle'>
          <span className='ru'>природа</span>
          <span className='en'>nature</span>
        </p>
        <p className='promo-text __bottom' ref={textBottom}>
          <span className='ru'>событиЍ</span>
          <span className='en'>of&nbsp;events</span>
        </p>

        <div className='promo-pandaLinkWrap'>
          <a ref={linkRef} href='/cases' className='promo-pandaLink' onClick={handleLinkClick}>
            <picture>
              <source srcSet={`${process.env.PUBLIC_URL}/images/promo-panda.webp`} type='image/webp' />
              <img className='promo-pandaImage' src={`${process.env.PUBLIC_URL}/images/promo-panda.png`} alt='Человек в костюме панды' />
            </picture>
          </a>
        </div>

        <div className='promo-pandaTextWrap'>
          <a href='/cases' className='promo-pandaText' onClick={handleLinkClick}>
            <span className='ru'>Узнай, кто<br />под маской?</span>
            <span className='en'>Who’s behind<br />the mask?</span>
          </a>
        </div>
      </section>
    </CSSTransition>
  )
}
