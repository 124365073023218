import { debounce } from './debounce'

/**
 * @param {HTMLElement} element
 * @param {(v?: boolean) => void} changeCase
 */
export const setMouseWheelHandler = (element, changeCase) => {
  if (!element) return

  const mouseWheelhandler = debounce(evt => {
    evt = evt || window.event
    var delta = evt.deltaY || evt.detail || evt.wheelDelta
    if (delta < 0) {
      changeCase(true)
    } else if (delta >= 0) {
      changeCase()
    }
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false)
  }, 600, true)

  if ('onwheel' in document) {
    element.addEventListener('wheel', mouseWheelhandler)
  } else if ('onmousewheel' in document) {
    element.addEventListener('mousewheel', mouseWheelhandler)
  } else {
    element.addEventListener('MozMousePixelScroll', mouseWheelhandler)
  }
}
