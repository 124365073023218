export const newClassic = {
  type: 'screen',
  color: '#1da0a1',
  eventType: {
    ru: 'Клиентское мероприятие',
    en: 'Client event'
  },
  title: {
    ru: 'НОВАЯ КЛАССИКА',
    en: 'New classic'
  },
  link: 'new-classic',
  image: 'emotions',
  bgcolor: '#192828',
  page: {
    image: '07-main-photo-min.jpg',
    meta: {
      date: {
        ru: 'май 2018',
        en: 'MAY 2018'
      },
      location: {
        ru: 'Россия, Сантк-Петербург',
        en: 'RUSSIA, SAINT PETERSBURG'
      },
      events: [
        {
          ru: 'СОБЫТИЕ ГОДА - 2018',
          type: 'eventYear'
        }
      ],
      tags: []
    },
    intro: {
      ru: 'Как представить историю и искусство в новом технологичном ключе и отразить это абсолютно во всех элементах вечера — от декора до шоу на сцене? Как создать мост между классикой и прогрессивностью?',
      en: 'Как представить историю и искусство в новом технологичном ключе и отразить это абсолютно во всех элементах вечера — от декора до шоу на сцене? Как создать мост между классикой и прогрессивностью?'
    },
    video: 'https://www.youtube.com/embed/GYR6rWinYzQ?autoplay=0',
    videoImage: null,
    gradient: 'linear-gradient(150deg,#1da0a1,#0278c8)',
    gallery: {
      small: ['0709-Small.jpg', '0705-Small.jpg'],
      large: []
    },
    gallery2: {
      small: ['0701-Small.jpg', '0708-Small.jpg'],
      large: ['0703-Small.jpg']
    },
    gallery3: {
      small: [],
      large: ['0704-Small.jpg']
    },
    content: {
      image: 'classic.png',
      text: {
        ru: '<p class="ru"><b>Пространство ЦВЗ «Манеж»</b> было полностью наполнено концептуально. В основу декора лег синтез мотивов природы и инновационных технологий. В результате «Манеж» стал местом для глобальных инсталляций из тысяч тюльпанов, а также нескольких арт-объектов, созданных специально для мероприятия. Символом классики, которая остается актуальной во все времена и меняет мир, стал ансамбль имени Игоря Моисеева.</p>',
        en: '<p class="en"><b>Пространство ЦВЗ «Манеж»</b> было полностью наполнено концептуально. В основу декора лег синтез мотивов природы и инновационных технологий. В результате «Манеж» стал местом для глобальных инсталляций из тысяч тюльпанов, а также нескольких арт-объектов, созданных специально для мероприятия. Символом классики, которая остается актуальной во все времена и меняет мир, стал ансамбль имени Игоря Моисеева.</p>',
        fz: '21'
      }
    },
    content2: {
      title: '<p class="ru">Концепция «Новой классики» — это сочетание уважения к традициям и открытости новым идеям и решениям.</p><p class="en">Концепция «Новой классики» — это сочетание уважения к традициям и открытости новым идеям и решениям.</p>',
      fzTitle: '31',
      text: '<p class="ru"><b>Гости приема смогли не только увидеть выступление коллектива, но и прочувствовать его историю через уникальную мультимедийную ретроспективу.</b></p><p class="en"><b>Гости приема смогли не только увидеть выступление коллектива, но и прочувствовать его историю через уникальную мультимедийную ретроспективу.</b></p><p class="ru">Та же мысль синтеза впечатлений была заложена и в драматургию шоу на сцене: ведущим стал символ классики новой эпохи, перевернувшей мир медиа, — виджей канала MTV Александр Анатольевич</p><p class="en">Та же мысль синтеза впечатлений была заложена и в драматургию шоу на сцене: ведущим стал символ классики новой эпохи, перевернувшей мир медиа, — виджей канала MTV Александр Анатольевич</p>',
      fzText: '19'
    },
    content3: {
      left: '<div class="text-bold"><p class="ru">— 7 дней монтажа</p><p class="en">— 7 дней монтажа</p><p class="ru">— 180 тонн оборудования</p><p class="en">— 180 тонн оборудования</p><p class="ru">— 21000 цветов</p><p class="en">— 21000 цветов</p><p class="ru">— 350 человек персонала</p><p class="en">— 350 человек персонала</p><p class="ru">— 1 команда</p><p class="en">— 1 команда</p><p class="block-line block-line-bottom"></p></div><br><p class="ru text-normal txt-size-10">*Событие организовано совместно с компанией «Live Group»</p><p class="en text-normal txt-size-10">*Событие организовано совместно с компанией «Live Group»</p>',
      fzLeft: '16',
      right: '<div class="text-normal"><p class="ru">Музыкальным хедлайнером — лирично зажигательный Илья Лагутенко и группа «Мумий Тролль» со специальной программой. Цельность, свежесть и атмосферную концептуальность торжественного приема по достоинству оценили первые лица «Газпромбанка» и многочисленные гости форума.</p><p class="en">Музыкальным хедлайнером — лирично зажигательный Илья Лагутенко и группа «Мумий Тролль» со специальной программой. Цельность, свежесть и атмосферную концептуальность торжественного приема по достоинству оценили первые лица «Газпромбанка» и многочисленные гости форума.</p><p class="ru">Приём получил награду «Лучшее клиентское мероприятие» по версии премии «Событие года».</p><p class="en">Приём получил награду «Лучшее клиентское мероприятие» по версии премии «Событие года».</p></div>',
      fzRight: '18'
    }
  }
}
