import React from 'react'

import './video.scss'

export const Video = () => {
  return (
    <section className='video'>
      <h3 className='visually-hidden'>Видео</h3>
      <div className='video-inner'>
        <div className='video-iframeWrap'>
          <iframe className='video-iframe' title='panda video' src='https://www.youtube.com/embed/6WfvYUAC-As?autoplay=0' frameBorder='0' allowFullScreen='1' />
        </div>
      </div>
    </section>
  )
}
