export const oneDayOfAlexandersLife = {
  type: 'screen',
  color: '#4facfe',
  eventType: {
    ru: 'СВЕТСКОЕ МЕРОПРИЯТИЕ',
    en: 'СВЕТСКОЕ МЕРОПРИЯТИЕ'
  },
  title: {
    ru: 'ОДИН ДЕНЬ ИЗ ЖИЗНИ АЛЕКСАНДРА I',
    en: 'ОДИН ДЕНЬ ИЗ ЖИЗНИ АЛЕКСАНДРА I'
  },
  link: 'one-day-of-alexanders-life',
  bgcolor: '#3e7cc5',
  page: {
    image: '14.jpg',
    meta: {
      date: {
        ru: 'ДЕКАБРЬ 2021',
        en: 'December 2021'
      },
      location: {
        ru: 'Россия, Санкт-Петербург',
        en: 'RUSSIA, SAINT PETERSBURG'
      },
      events: [],
      tags: []
    },
    intro: {
      ru: `<b>Реально ли перенести гостя на два столетия назад?</b><br /> ${'Один день из жизни Александра I'} - не просто концепт, а действительно погружение в иную эпоху. Гости раута компании Breguet, оказавшись в Царскосельском Екатерининском дворце, смогли прожить этот день императора Александра I, получив уникальный эмоциональный опыт.`,
      en: `<b>Реально ли перенести гостя на два столетия назад?</b><br /> ${'Один день из жизни Александра I'} - не просто концепт, а действительно погружение в иную эпоху. Гости раута компании Breguet, оказавшись в Царскосельском Екатерининском дворце, смогли прожить этот день императора Александра I, получив уникальный эмоциональный опыт.`
    },
    video: 'https://www.youtube.com/embed/BUxC2qOn8mQ',
    videoImage: null,
    gradient: 'linear-gradient(150deg,#f4d9fd,#3e7cc5)',
    gallery: {
      small: ['08.jpg', '06.JPG'],
      large: ['13.jpg']
    },
    gallery2: {
      small: ['09.jpg', '05.jpg'],
      large: ['03.JPG']
    },
    gallery3: {
      small: [],
      large: ['11.jpeg']
    },
    content: {
      image: '12.jpg',
      imageSmall: false,
      text: {
        ru: '<p class="ru"><b>В подготовке мы досконально изучили документы времени и извлекли действительно определяющие факторы жизни императора.</b></p><p class="ru">Что ел император на завтрак? Как проходили его прогулки? Почему, имея свой дворец рядом, он большую часть времени проводил в Екатерининском дворце, принадлежащем его бабушке? Именно эти привычки сформировали личность императора, а через нее - ритм той эпохи.<br />Из полученных фактов и сложился «путь гостя», включающий в себя проживание в Tsar Palace Luxury Hotel, посещение Александровского дворца, программу в парке и гала-ужин в Екатерининском дворце. Всё это обеспечило органичный переход к главному событию: торжеству, посвященному 220-летию Breguet Tourbillon.</p>',
        en: '<p class="en"><b>В подготовке мы досконально изучили документы времени и извлекли действительно определяющие факторы жизни императора.</b></p><p class="en">Что ел император на завтрак? Как проходили его прогулки? Почему, имея свой дворец рядом, он большую часть времени проводил в Екатерининском дворце, принадлежащем его бабушке? Именно эти привычки сформировали личность императора, а через нее - ритм той эпохи.<br />Из полученных фактов и сложился «путь гостя», включающий в себя проживание в Tsar Palace Luxury Hotel, посещение Александровского дворца, программу в парке и гала-ужин в Екатерининском дворце. Всё это обеспечило органичный переход к главному событию: торжеству, посвященному 220-летию Breguet Tourbillon.</p>',
        fz: '17'
      }
    },
    content2: {
      title: '<p class="ru">' +
        'Кроме того, рассадка гостей также была символична и глубока по идее: расположение столов отсылало к расположению стрелок часов на логотипе Breguet. ' +
        '</p>' +
        '<p class="en">' +
        'Кроме того, рассадка гостей также была символична и глубока по идее: расположение столов отсылало к расположению стрелок часов на логотипе Breguet. ' +
        '</p>',
      fzTitle: '31',
      text: '<p class="ru">' +
        'А в завершении вечера в знак признательности каждому гостю был вручен сувенир, связанный с поэмой Пушкина «Евгений Онегин», в память об одном из первых русских клиентов Breguet.\n' +
        '</p>' +
        '<p class="en">' +
        'А в завершении вечера в знак признательности каждому гостю был вручен сувенир, связанный с поэмой Пушкина «Евгений Онегин», в память об одном из первых русских клиентов Breguet.\n' +
        '</p>' +
        '<p class="ru">' +
        'Помимо эстетической, была достигнута и бизнес-цель мероприятия: продажи часов Breguet гостям после события превысили бюджет проекта в 2,5 раза.' +
        '</p>' +
        '<p class="en">' +
        'Помимо эстетической, была достигнута и бизнес-цель мероприятия: продажи часов Breguet гостям после события превысили бюджет проекта в 2,5 раза.' +
        '</p>',
      fzText: '20'
    },
    content3: {
      left: '<br><br><br><br><p class="block-line block-line-bottom"></p>',
      fzLeft: '19',
      right: '<p class="ru text-bold">Эта история доказывает: свет по-настоящему эпохальных явлений всегда присутствует в ландшафте реальности. А чтобы прикоснуться к легенде и осязать время, нужно лишь одно: передать чувство восхищения от творца к зрителю, от идеи к ценителю.</p><p class="en text-bold">Эта история доказывает: свет по-настоящему эпохальных явлений всегда присутствует в ландшафте реальности. А чтобы прикоснуться к легенде и осязать время, нужно лишь одно: передать чувство восхищения от творца к зрителю, от идеи к ценителю.</p>',
      fzRight: '19'
    }
  }
}
