export const fashionHighway = {
  type: 'screen',
  color: '#0278c8',
  eventType: {
    ru: 'Fashion показ',
    en: 'Fashion show'
  },
  title: {
    ru: 'Модная магистраль',
    en: 'Fashion highway'
  },
  link: 'fashion-highway',
  bgcolor: '#17252e',
  page: {
    image: '08-main-photo-min.jpg',
    meta: {
      date: {
        ru: 'ноябрь 2018',
        en: 'NOVEMBER 2018'
      },
      location: {
        ru: 'Россия, Санкт-Петербург',
        en: 'RUSSIA, SAINT PETERSBURG'
      },
      events: [
        {
          ru: 'Финалист «Событие года» в номинации «Fashion show»'
        }
      ],
      tags: []
    },
    intro: {
      ru: 'В Санкт-Петербурге всегда царил культ стиля, что нашло свое отражение в архитектуре, искусстве и постоянном интересе со стороны гостей города. Эту неотъемлемую часть характера Северной столицы необходимо подчеркнуть в свежем fashion-формате - что мы и сделали.',
      en: 'В Санкт-Петербурге всегда царил культ стиля, что нашло свое отражение в архитектуре, искусстве и постоянном интересе со стороны гостей города. Эту неотъемлемую часть характера Северной столицы необходимо подчеркнуть в свежем fashion-формате - что мы и сделали.'
    },
    video: 'https://www.youtube.com/embed/6oJQfeUZGqc?autoplay=0',
    videoImage: null,
    gradient: 'linear-gradient(150deg,#0278c8,#4760fd)',
    gallery: {
      small: [],
      large: ['0801-Small-min.jpg']
    },
    gallery3: {
      small: ['0803-Small-min.jpg', '0802-Small-min.jpg'],
      large: []
    },
    gallery2: null,
    content: {
      image: 'fashion.png',
      text: {
        ru: '<p class="ru"><b>Повышение узнаваемости бренда</b> «300 лет Невскому проспекту» среди целевой аудитории мероприятия «Невский — модная магистраль Санкт-Петербурга» произошло благодаря стильному и актуальному контенту.</p>',
        en: '<p class="en"><b>Повышение узнаваемости бренда</b> «300 лет Невскому проспекту» среди целевой аудитории мероприятия «Невский — модная магистраль Санкт-Петербурга» произошло благодаря стильному и актуальному контенту.</p>',
        fz: '25'
      }
    },
    content3: {
      left: '<div class="text-bold"><p class="ru">Одновременно на площадке мероприятия — в Санкт-Петербургской государственной художественно-промышленной академии имени Штиглица — прошли выставки исторического костюма из коллекции «Сокровища Петербурга» Натальи Костригиной и модные показы от звезд — медийных персон международного уровня David Koma и Igor Gulyaev.</p><p class="en">Одновременно на площадке мероприятия — в Санкт-Петербургской государственной художественно-промышленной академии имени Штиглица — прошли выставки исторического костюма из коллекции «Сокровища Петербурга» Натальи Костригиной и модные показы от звезд — медийных персон международного уровня David Koma и Igor Gulyaev.</p></div>',
      fzLeft: '18',
      right: '<div class="text-normal"><p class="ru">Это способствовало созданию позитивной эмоциональной и ассоциативной связи целевой аудитории и бренда «300 лет Невскому проспекту». Об этом свидетельствует позитивное освещение мероприятия в прессе и социальных сетях.</p><p class="en">Это способствовало созданию позитивной эмоциональной и ассоциативной связи целевой аудитории и бренда «300 лет Невскому проспекту». Об этом свидетельствует позитивное освещение мероприятия в прессе и социальных сетях.</p></div>',
      fzRight: '23'
    },
    content2: null
  }
}
