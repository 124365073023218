export const atFactory = {
  type: 'screen',
  color: '#af0b87',
  eventType: {
    ru: 'Family Day'
  },
  title: {
    ru: 'На заводe — <br>как дома',
    en: 'AT FACTORY – LIKE AT HOME!'
  },
  link: 'at-factory',
  bgcolor: '#291624',
  page: {
    image: '071.jpg',
    meta: {
      date: {
        ru: 'июнь 2017',
        en: 'JUNE 2017'
      },
      location: {
        ru: 'Санкт-Петербург',
        en: 'SAINT PETERSBURG'
      },
      events: [],
      tags: []
    },
    intro: {
      ru: 'Сотрудничество с известным автомобильным брендом для нас уже традиция. Каждый год мы придумываем и реализуем большой family day на самых разных площадках, будь то экофестиваль в деревне Шуваловка или пикник в модном «Петровском арсенале»…',
      en: 'Collaboration with well-known car brand is a kind of tradition for us already. Every year we develop and embody a large family day at different venues – if it’s an eco-festival in Shuvalovka village or a picnic in a chic "Petrovsky arsenal"…'
    },
    video: null,
    videoImage: 'IMG_1596.jpg',
    gradient: 'linear-gradient(150deg,#af0b87,#683cf7)',
    gallery: {
      small: ['IMG_1725.jpg', 'KK_4252.jpg'],
      large: ['toyota-mobile-photo.jpg']
    },
    gallery2: {
      small: ['IMG_8847.jpg', 'IMG_8900.jpg'],
      large: []
    },
    gallery3: null,
    content: {
      imageText: {
        ru: '<p class="ru"><span>6000</span>участников праздника!</p>',
        en: '<p class="en"><span>6000</span> participants of the event!</p>'
      },
      image: null,
      text: {
        ru: '<p class="ru">В 2017 году местом проведения была выбрана территория самого завода, а ключевым моментом должна была стать экскурсия для всей семьи на производство. Как же в этой локации донести идею семейности, а главное — ценность домашнего уюта, да еще и для шести тысяч участников? Все гениальное просто!</p>',
        en: '<p class="en">In 2017 the factory itself has been chosen as the location for the event and the tour for families around the manufacturing was meant to become the key aspect of the event. How to carry out the idea of family in this location? And mainly – the idea of domesticity and what is more – for six thousands of participants? Nothing succeeds like simplicity!</p>',
        fz: '19'
      }
    },
    content2: {
      title: '<p class="ru">Как создать уютную семейную атмосферу даже на территории завода и чем удивлять клиента третий год подряд?</p><p class="en">How to create a cozy home feeling even at the territory of the factory and how to surprise the customer third year in a row?</p>',
      fzTitle: '31',
      text: '<p class="ru">Мы разделили территорию на зоны, каждая из которых представляла одну из комнат любого уютного дома: детская, игровая, кухня, мансарда, тренажерный зал и так далее.</p><p class="en">We have divided the territory into zones, each of those represented a room of any cozy home: children’s room, game rooms, kitchen, attic, exercise room and etc.</p><p class="ru">При этом программа одновременно проходила на нескольких сценах: главной, детской и молодежной, объединяя разные виды активности для каждого присутствующего. <b>Таким образом нам удалось элегантно совместить несколько мероприятий в одном глобальном!</b> При этом экскурсия по заводу также была задумана как совершенно неформальная, интересная как взрослым, так и детям.</p><p class="en">However the program performance took place at several stages simultaneously: main, child and youth, uniting different types of activities for every guest. Thereby we managed to gracefully combine several events into one comprehensive event! At that the tour around the factory was intended as well to be informal and as interesting for adults as also for kids.</p>',
      fzText: '19'
    },
    content3: {
      left: '<p>– Около 6000 гостей</p><p>– 16000 м² площадь застройки</p><p>– Более 240 человек персонала</p><p>– 52 вида активности</p><p>– 8 м³ розданных подарков</p><p class="block-line block-line-bottom"></p>',
      fzLeft: '19',
      right: '<p class="ru">Завершился этот насыщенный, уютный и по-настоящему семейный день на заводе концертом группы «Фабрика» — разве мог коллектив с другим названием столь органично вписаться в антураж площадки?</p><p class="en">This intensive, homelike and truly family day has finished with the concert show of “Fabrika” (Russian – “Factory”) band at the factory – was it possible to fit any other group so naturally in the concept of the venue?</p>',
      fzRight: '19'
    }
  }
}
