/* global FormData, fetch */

import React, { useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx'
import { Input } from './input'
import { FormButton } from './form-button'
import { classNames } from '../utils/class-names'

import './form.scss'

const inputs = [
  {
    name: 'name',
    placeholder: {
      ru: 'Имя',
      en: 'Name'
    }
  },
  {
    name: 'project',
    placeholder: {
      ru: 'Проект',
      en: 'Project'
    }
  },
  {
    name: 'email',
    placeholder: {
      ru: 'E-Mail',
      en: 'E-Mail'
    }
  },
  {
    name: 'company',
    placeholder: {
      ru: 'Компания',
      en: 'Company'
    }
  }
]

const services = [
  {
    ru: 'Корпоративное мероприятие',
    en: 'Corporate event'
  },
  {
    ru: 'Team building',
    en: 'Team building'
  },
  {
    ru: 'Презентация',
    en: 'Presentation'
  },
  {
    ru: 'Family day',
    en: 'Family day'
  },
  {
    ru: 'Лонч',
    en: 'Launch'
  },
  {
    ru: 'Тест-драйв',
    en: 'Test drive'
  },
  {
    ru: 'Конференция',
    en: 'Conference'
  },
  {
    ru: 'Выставка',
    en: 'Exhibition'
  },
  {
    ru: 'm.i.c.e.',
    en: 'M.I.C.E.'
  },
  {
    ru: 'Клиентское мероприятие',
    en: 'Customer event'
  },
  {
    ru: 'Спортивное событие',
    en: 'Sport event'
  },
  {
    ru: 'Спонсорская интеграция',
    en: 'Sponsor integration'
  }
]

const budgets = [
  {
    ru: '0,5–1 млн руб.',
    en: '0,5-1 million RUB'
  },
  {
    ru: '1–2 млн руб.',
    en: '1–2 million RUB'
  },
  {
    ru: '2–5 млн руб.',
    en: '2–5 million RUB'
  },
  {
    ru: 'от 5 млн руб.',
    en: 'over 5 million RUB'
  }
]

export const Form = ({ contactsPage, isShowForm, setShowForm }) => {
  const [isEn, setEn] = useState(false)
  const [isError, setError] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const formRef = useRef(null)

  useEffect(() => {
    setEn(document.documentElement.lang === 'en')
  }, [isShowForm])

  const handleCloseButtonClick = evt => {
    evt.preventDefault()
    setShowForm(false)
  }

  const handleEscKeydown = evt => {
    if (evt.keyCode === 27) {
      setShowForm(false)
    }
  }

  const handleFormSend = evt => {
    evt.preventDefault()

    const formData = new FormData(formRef.current)
    if (!formData.get('email')) {
      setError(true)
      return
    }

    setError(false)
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      project: formData.get('project'),
      company: formData.get('company'),
      budget: formData.get('budget'),
      services: services.filter((_, i) => formData.get(`service-${i}`)).map(service => isEn ? service.en : service.ru)
    }

    fetch('mailer/mail.php', {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(() => {
        setSuccess(true)

        setTimeout(() => {
          setSuccess(false)
          formRef.current.reset()
          !contactsPage && setShowForm(false)
        }, 1200)
      })
  }

  useEffect(() => {
    !contactsPage && document.addEventListener('keydown', handleEscKeydown)
    return () => {
      !contactsPage && document.removeEventListener('keydown', handleEscKeydown)
    }
  })

  return (
    <CSSTransition
      in={isShowForm}
      timeout={550}
      classNames={classNames}
      unmountOnExit
    >
      <section className={clsx('formWrap', contactsPage && '__contactsPage')}>
        <h2 className='visually-hidden'>форма заказа услуг</h2>
        <a href='/' className='formWrap-close' onClick={handleCloseButtonClick}>Закрыть</a>
        <form ref={formRef} className='form' onSubmit={handleFormSend}>
          <div className='form-group'>
            {inputs.map(it => (
              <div key={it.name} className='form-inputWrap'>
                <Input name={it.name} placeholder={isEn ? it.placeholder.en : it.placeholder.ru} />
              </div>
            ))}
          </div>
          <div className='form-services'>
            <h3 className='form-subtitle'>
              <span className='ru'>Услуги</span>
              <span className='en'>Services</span>
            </h3>
            <span className='form-desc'>
              <span className='ru'>возможно выбрать несколько</span>
              <span className='en'>possible to pick a number</span>
            </span>

            <div className='form-group'>
              {services.map((it, i) => (
                <div key={i} className='form-inputWrap __services'>
                  <FormButton key={i} text={isEn ? it.en : it.ru} type='checkbox' name={`service-${i}`} />
                </div>
              ))}
            </div>
          </div>

          <div className='form-group'>
            <h3 className='form-subtitle'>
              <span className='ru'>Комментарий</span>
              <span className='en'>Comments</span>
            </h3>
            <div className='form-inputWrap __comment'>
              <textarea className='form-textarea' />
            </div>
          </div>

          <div className='form-budget'>
            <h3 className='form-subtitle'>
              <span className='ru'>Примерный бюждет</span>
              <span className='en'>Approx. budget</span>
            </h3>
            <span className='form-desc'>
              <span className='ru'>выберите диапазон</span>
              <span className='en'>select range</span>
            </span>
            <div className='form-group'>
              {budgets.map((it, i) => (
                <div key={i} className='form-inputWrap __budget'>
                  <FormButton key={i} text={isEn ? it.en : it.ru} type='radio' name='budget' />
                </div>
              ))}
            </div>
          </div>
          <button className='form-send'>
            <span className='ru'>Отправить</span>
            <span className='en'>Send</span>
          </button>
          <div className='form-messageWrap'>
            <p className={clsx('form-message __success', isSuccess && '__active')}>
              <span className='ru'>Ваша заявка успешно отправлена</span>
              <span className='en'>Your application has been sent successfully</span>
            </p>
            <p className={clsx('form-message __error', isError && '__active')}>
              <span className='ru'>Необходимо заполнить все поля</span>
              <span className='en'>All fields are required</span>
            </p>
          </div>
        </form>
      </section>
    </CSSTransition>
  )
}
