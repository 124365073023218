import { useEffect, useState } from 'react'

const langKey = 'panda-lang'

const getLang = () => localStorage.getItem(langKey)

/**
 * @param {string} l - 'ru' or 'en'
 */
export const storeLang = l => localStorage.setItem(langKey, l)

/**
 * Languge switch hook
 * @returns {[string, React.Dispatch<React.SetStateAction<string>>]}
 */
export function useLang () {
  const [lang, setLang] = useState('ru')

  useEffect(() => {
    setLang(getLang() === 'en' ? 'en' : 'ru')
  }, [])
  useEffect(() => {
    storeLang(lang)
    document.documentElement.lang = lang
  }, [lang])
  return [lang, setLang]
}
