import { promo } from './screens/promo'
import { togetherShow } from './screens/together-show'
import { emotions } from './screens/emotions'
import { behindTheMask } from './screens/behind-the-mask'
import { semcity } from './screens/semcity'
import { silkWay } from './screens/silk-way'
import { generationOfReal } from './screens/generation-of-real'
import { fromTheHeart } from './screens/from-the-heart'
import { newClassic } from './screens/new-classic'
import { fashionHighway } from './screens/fashion-highway'
import { bridgeFuture } from './screens/bridge-future'
import { hyggeParty } from './screens/hygge-party'
import { atFactory } from './screens/at-factory'
import { allUpToKopeyka } from './screens/all-up-to-kopeyka'
import { oneDayOfAlexandersLife } from './screens/one-day-of-alexanders-life'

export const screens = [
  promo,
  togetherShow,
  emotions,
  behindTheMask,
  semcity,
  silkWay,
  generationOfReal,
  fromTheHeart,
  newClassic,
  fashionHighway,
  bridgeFuture,
  hyggeParty,
  atFactory,
  allUpToKopeyka,
  oneDayOfAlexandersLife
]
