export const clientsData = [
  {
    link: 'https://5ka.ru/',
    imgActive: 'pyaterochka.png',
    img: 'pyaterochka--inactive.png'
  },
  {
    link: 'http://www.silkwayrally.com/',
    imgActive: 'silkway.png',
    img: 'silkway--inactive.png'
  },
  {
    link: 'https://www.bmw.ru/ru/index.html',
    imgActive: 'bmw.png',
    img: 'bmw--inactive.png'
  },
  {
    link: 'http://www.jll.ru/russia/ru-ru',
    imgActive: 'jll.png',
    img: 'jll--inactive.png'
  },
  {
    link: 'https://www.t-mobile.com/',
    imgActive: 't-mobile.png',
    img: 't-mobile--inactive.png'
  },
  {
    link: 'https://tele2.ru/',
    imgActive: 'tele2.png',
    img: 'tele2--inactive.png'
  },
  {
    link: 'http://fc-zenit.ru/',
    imgActive: 'zenit.png',
    img: 'zenit--inactive.png'
  },
  {
    link: 'https://www.jde.ru/',
    imgActive: 'jde.png',
    img: 'jde--inactive.png'
  },
  {
    link: 'https://www.coca-cola.ru/',
    imgActive: 'cocacola.png',
    img: 'cocacola--inactive.png'
  },
  {
    link: 'https://gov.spb.ru/',
    imgActive: 'spb.png',
    img: 'spb--inactive.png'
  },
  {
    link: 'https://ok.ru/',
    imgActive: 'odnoklassniki.png',
    img: 'odnoklassniki--inactive.png'
  },
  {
    link: 'https://biocad.ru/',
    imgActive: 'biocad.png',
    img: 'biocad--inactive.png'
  },
  {
    link: 'http://zarina.ru/',
    imgActive: 'zarina.png',
    img: 'zarina--inactive.png'
  },
  {
    link: 'https://www.toyota.ru/',
    imgActive: 'toyota.png',
    img: 'toyota--inactive.png'
  },
  {
    link: 'http://megafon.ru/',
    imgActive: 'megafon.png',
    img: 'megafon--inactive.png'
  },
  {
    link: 'http://ica-construction.ru/',
    imgActive: 'ica.png',
    img: 'ica--inactive.png'
  },
  {
    link: 'https://www.x5.ru/ru',
    imgActive: 'x5.png',
    img: 'x5--inactive.png'
  }
]
