import React from 'react'

import './statistics.scss'

export const Statistics = () => {
  return (
    <section className='statistics'>
      <div className='statistics-record'>
        <div className='statistics-recordInner'>
          <h3 className='statistics-recordTitle'>
            <span className='ru'>Достижения</span>
            <span className='en'>ACHIEVEMENTS</span>
          </h3>
          <ul className='statistics-recordList'>
            <li className='statistics-recordItem __eventYear'>Событие года - 2017</li>
            <li className='statistics-recordItem __mercury'>Серебряный Меркурий - 2018</li>
            <li className='statistics-recordItem __eventYear'>Событие года - 2018</li>
            <li className='statistics-recordItem __top'>Топ предприятий России-2018</li>
            <li className='statistics-recordItem __eventYear'>Событие года - 2019</li>
            <li className='statistics-recordItem __beaworld'>Beaworld - 2019</li>
            <li className='statistics-recordItem __eventex'>Eventex awards - 2020</li>
            <li className='statistics-recordItem __eventYear'>Событие года - 2021</li>
          </ul>
        </div>
      </div>
      <div className='statistics-promo'>
        <p className='statistics-promoText'>
          <span className='ru'>Современным миром управляют идеи. Именно они объединяют людей, дарят им эмоции и вдохновляют. Так случилось с нами и поэтому мы стали стаей.</span>
          <span className='en'>Modern world is led by the ideas. The ideas unite people, gift them emotions and inspire. So this happened to us and that is why we became a pack.</span>
        </p>
      </div>
    </section>
  )
}
