export const generationOfReal = {
  type: 'screen',
  color: '#4facfe',
  eventType: {
    ru: 'Серия мероприятий',
    en: '"Generation of real" PARTIES SERIES'
  },
  title: {
    ru: 'Поколение настоящих',
    en: 'Generation of real'
  },
  link: 'generation-of-real',
  bgcolor: '#263a4c',
  page: {
    image: '05-main-photo-min.jpg',
    meta: {
      date: {
        ru: 'январь 2018',
        en: 'january 2018'
      },
      location: {
        ru: 'Россия, Санкт-Петербург',
        en: 'RUSSIA, SAINT PETERSBURG'
      },
      events: [],
      tags: []
    },
    intro: {
      ru: 'Организовать большое событие в одном месте - это уже непросто. Но что, если оно разделяется на три мероприятия за вечер в разных точках города, каждое из которых имеет свою тональность, наполнение и аудиторию? Вызов принят!',
      en: 'Организовать большое событие в одном месте - это уже непросто. Но что, если оно разделяется на три мероприятия за вечер в разных точках города, каждое из которых имеет свою тональность, наполнение и аудиторию? Вызов принят!'
    },
    video: 'https://www.youtube.com/embed/uDnEGP0AiRQ?autoplay=0',
    videoImage: null,
    gradient: 'linear-gradient(150deg,#4facfe,#21c557)',
    gallery: {
      small: ['0511-Small-min.jpg', '0513-Small.jpg'],
      large: []
    },
    gallery2: {
      small: ['0501-Small-min.jpg', '0505-Small-min.jpg'],
      large: []
    },
    gallery3: {
      small: ['0507-Small-min.jpg', '0506-Small-min.jpg', '0512-Small-min.jpg', '0508-Small-min.jpg'],
      large: []
    },
    content: {
      image: 'generation.png',
      text: {
        ru: '<p class="ru">Концепция «50 лет растем вместе» была реализована в Михайловском театре, «Ленинград Центре» и атриуме Петропавловской крепости. Первым этапом стало торжественное мероприятие в Михайловском театре, которое вел Кирилл Набутов. Для гостей выступили солисты театра, а также мы подготовили графический фильм «Поколения настоящих», погружающий в историю компании, и игровой короткометражный фильм, основанный на реальных событиях.</p><p class="ru"><b>После гости разделились на две половины и отправились на мероприятия, прошедшие параллельно: в атриуме Петропавловской крепости и Ленинград Центре.</b></p>',
        en: '<p class="en">Концепция «50 лет растем вместе» была реализована в Михайловском театре, «Ленинград Центре» и атриуме Петропавловской крепости. Первым этапом стало торжественное мероприятие в Михайловском театре, которое вел Кирилл Набутов. Для гостей выступили солисты театра, а также мы подготовили графический фильм «Поколения настоящих», погружающий в историю компании, и игровой короткометражный фильм, основанный на реальных событиях.</p><p class="en"><b>После гости разделились на две половины и отправились на мероприятия, прошедшие параллельно: в атриуме Петропавловской крепости и Ленинград Центре.</b></p>',
        fz: '17'
      }
    },
    content2: {
      title: '<p class="ru">В первой локации, где преобладали действующие сотрудники компании и ее ветераны, получила развитие тема «Растем вместе…».</p><p class="en">В первой локации, где преобладали действующие сотрудники компании и ее ветераны, получила развитие тема «Растем вместе…».</p>',
      fzTitle: '31',
      text: '<p class="ru"><b>Она была подчеркнута в каждой детали, включая декор пространства в живом, зеленом исполнении. Кульминацией вечера стало выступление Веры Брежневой.</b></p><p class="en"><b>Она была подчеркнута в каждой детали, включая декор пространства в живом, зеленом исполнении. Кульминацией вечера стало выступление Веры Брежневой.</b></p><p class="ru">Во второй локации была подчеркнута тема глобальности деятельности ЛГСС – «…от Калининграда до Владивостока». Здесь правил бал уже в роли ведущего Николай Басков. На сцене появилась целая россыпь звезд всех возможных жанров.</p><p class="en">Во второй локации была подчеркнута тема глобальности деятельности ЛГСС – «…от Калининграда до Владивостока». Здесь правил бал уже в роли ведущего Николай Басков. На сцене появилась целая россыпь звезд всех возможных жанров.</p>',
      fzText: '20'
    },
    content3: {
      left: '<br><br><br><br><p class="block-line block-line-bottom"></p>',
      fzLeft: '19',
      right: '<p class="ru text-bold">Сергей Мазаев и оркестр, команда КВН «Союз», лауреаты английского шоу Britain got a talent «ЮДИ», а также Вера Брежнева, успевшая порадовать гостей на двух площадках в течение одного вечера.</p><p class="en text-bold">Сергей Мазаев и оркестр, команда КВН «Союз», лауреаты английского шоу Britain got a talent «ЮДИ», а также Вера Брежнева, успевшая порадовать гостей на двух площадках в течение одного вечера.</p>',
      fzRight: '19'
    }
  }
}
