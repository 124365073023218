export const silkWay = {
  type: 'screen',
  color: '#e2103d',
  eventType: {
    ru: 'Международный проект РАЛЛИ SILK WAY',
    en: '"SILK WAY" RALLY PARTIES SERIES'
  },
  title: {
    ru: 'ШелковыЍ путь',
    en: 'Silk Way'
  },
  link: 'silk-way',
  bgcolor: '#351d22',
  page: {
    image: '011.jpg',
    meta: {
      date: {
        ru: 'июль 2016',
        en: 'JULY 2016'
      },
      location: {
        ru: 'Россия, Казахстан, Китай',
        en: 'RUSSIA, KAZAKHSTAN, CHINA'
      },
      events: [],
      tags: ['#SILKWAYRALLY', '#SWPANDA']
    },
    intro: {
      ru: 'Что нужно человеку, чтобы наполнить жизнь яркими красками и настоящим драйвом? Конечно же, испытания, в которых можно проявить свои лучшие качества! Знаменитая международная гонка Silk Way Rally — отличный способ бросить вызов не только соперникам, но и самому себе!',
      en: 'What shall a person do to fill the life with bright shades and real drive vibe? Sure enough - it shall be challenges where one may reveal the best self! Famous race Silk Way Rally is a great chance to challenge not only other contestants but also yourself!'
    },
    video: 'https://www.youtube.com/embed/3ItxWydoYLU?autoplay=0',
    videoImage: null,
    gradient: 'linear-gradient(150deg,#e2103d,#4facfe)',
    gallery: {
      small: ['BY6I1370.jpg', 'BY6I1227.jpg'],
      large: ['BY6I3009.jpg']
    },
    gallery2: {
      small: ['031.jpg', 'BY6I0936.jpg'],
      large: []
    },
    gallery3: {
      small: [],
      large: ['502.jpg']
    },
    content: {
      image: '16v.png',
      text: {
        ru: '<p class="ru"><b>Silk Way Rally</b> предлагает участникам уникальную трассу: бескрайние российские и казахские степи, извилистые горные серпантины, сложнейшие дюны и песчаные массивы Китая. Гонка стартовала на Красной площади в Москве, а завершилась через 17 дней в Пекине.</p>',
        en: '<p class="en"><b>Silk Way Rally</b> suggests a unique track to its participants: endless Russian and Kazakh heaths, curvy mountain spiral roads, extremely challenging dunes and sand lands of China. Race had started in Moscow, at Red Square, and finished in 17 days in Beijing.</p>',
        fz: '19'
      }
    },
    content2: {
      title: '<p class="ru">Что такое испытать себя на прочность и провести 16 вечеринок в 16 городах подряд?</p><p class="en">What it’s like – to prove yourself and hold 16 parties in a row in 16 cities?</p>',
      fzTitle: '31',
      text: '<p class="ru"><b>Panda Project</b> обеспечила полное сопровождение команд, в каждом новом городе открывая для них место отдыха – грандиозный клуб. В итоге не гонщики искали шоу, а шоу по пятам следовало за ними! При этом не нужно было каждый раз вливаться в атмосферу и привыкать к новым людям — ведь за это время организаторы и гонщики стали практически одной семьей! Аудиторией вечеринок на бивуаке стали две тысячи человек, не считая зрителей.</p><p class="en"><b>Panda Project</b> has provided full support for teams – in each new city it had opened a resort area – an enormous club. So racers weren’t searching a show, but a show has been following them in hot pursuit! However, there was no need to tune up to a new setting and new people – as during the race period event hosts and racers became almost one family! Number of the visitors at bivouac parties was two thousand people, not including audience.</p>',
      fzText: '19'
    },
    content3: {
      left: '<p class="ru">– 2 500 человек в команде ралли</p><p class="en">– 2 500 rally teams participants</p><p class="ru">– 41 стран-участниц</p><p class="en">– 41 participating countries</p><p class="ru">– 125 спортивных экипажей</p><p class="en">– 125 sport crews</p><p class="ru">– 50 000 зрителей ежедневно вдоль маршрута следования</p><p class="en">– 50 000 of audience every day along the travel route</p><p class="block-line block-line-bottom"></p>',
      fzLeft: '19',
      right: '<p class="txt text-normal ru">Каждый резидент Silk Way Rally, добравшийся до финала, несомненно, стал творцом личного успеха.</p><p class="txt text-normal en">Every resident of Silk Way Rally who has reached the finish line – unmistakably became an architects of own fortunes.</p><p class="ru">И нам очень приятно, что мы тоже приняли этот челлендж, устроив событие по-настоящему достойно!</p><p class="en">And we were so honored to accept this challenge and to arrange the event at a laudable level.</p>',
      fzRight: '19'
    }
  }
}
