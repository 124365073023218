export const emotions = {
  type: 'screen',
  color: '#267dc0',
  bgcolor: '#1e2830',
  eventType: {
    ru: 'Торжественный бизнес-прием в рамках ПМЭФ',
    en: '"Emotions collection" sometext'
  },
  title: {
    ru: 'Собрание впечатлений',
    en: 'Emotions'
  },
  link: 'emotions',
  page: {
    image: '01-main-photo-min.jpg',
    meta: {
      date: {
        ru: 'июнь 2019',
        en: 'JUNE 2018'
      },
      location: {
        ru: '',
        en: ''
      },
      events: [
        {
          ru: 'Beaworld - 2019',
          type: 'beaworld'
        },
        {
          ru: 'СОБЫТИЕ ГОДА - 2019',
          type: 'eventYear'
        },
        {
          ru: 'Eventex awards - 2020',
          type: 'eventex'
        },
        {
          ru: 'СОБЫТИЕ ГОДА - 2021',
          type: 'eventYear'
        }
      ],
      tags: []
    },
    intro: {
      ru: 'Каким образом можно передать, что «Газпромбанк» подобен Санкт-Петербургу — так же наполняет возможностями тех, кто близок по духу; так же стирает границы, развивая отношения с клиентами и партнерами из других стран?',
      en: 'Some text'
    },
    video: 'https://www.youtube.com/embed/GfS3hBzAKO8?autoplay=0',
    videoImage: null,
    gallery: {
      small: ['0101-Small-min.jpg', '0106-Small-min.jpg'],
      large: []
    },
    gallery2: {
      small: ['0110-Small-min.jpg', '0102-Small-min.jpg'],
      large: []
    },
    gallery3: {
      small: [],
      large: ['0108-Small-min.jpg']
    },
    gradient: 'linear-gradient(150deg,#267dc0,#5e51f0)',
    content: {
      image: 'emotions.png',
      text: {
        ru: '<p class="ru"><b>На берегу Невы</b> был построен большой зеркальный павильон как образ сосредоточения новаторских творческих идей, витающих в воздухе Петербурга, — легкий, визуально будто парящий между стенами Петропавловской крепости.</p>',
        en: '<p class="en"><b>На берегу Невы</b> был построен большой зеркальный павильон как образ сосредоточения новаторских творческих идей, витающих в воздухе Петербурга, — легкий, визуально будто парящий между стенами Петропавловской крепости.</p>',
        fz: '27'
      }
    },
    content2: {
      title: '<p class="ru">В зале центральным объектом стал «Водопад» — вертикальный цилиндр из ткани с динамической проекцией.</p><p class="en">В зале центральным объектом стал «Водопад» — вертикальный цилиндр из ткани с динамической проекцией.</p>',
      fzTitle: '31',
      text: '<p class="ru">В начале шоу ткань с кинетического “Водопада” падала, символизируя освобождение идей, а внутри него оказалась эквилибристка на люстре. Каждый номер шоу транслировал главную идею: Санкт-Петербург — город, где каждая прогулка становится накоплением впечатлений. Именно в белую ночь знаковые места города на Неве откроются с новой стороны и подарят неповторимое вдохновение.</p><p class="en">В начале шоу ткань с кинетического “Водопада” падала, символизируя освобождение идей, а внутри него оказалась эквилибристка на люстре. Каждый номер шоу транслировал главную идею: Санкт-Петербург — город, где каждая прогулка становится накоплением впечатлений. Именно в белую ночь знаковые места города на Неве откроются с новой стороны и подарят неповторимое вдохновение. </p>',
      fzText: '19'
    },
    content3: {
      left: '<div class="text-bold"><p class="ru">– 1200 гостей</p><p class="en">– 1200 гостей</p><p class="ru">– 24 дня монтажа</p><p class="en">– 24 дня монтажа</p><p class="ru">– 104 тонны оборудования</p><p class="en">– 104 тонны оборудования</p><p class="ru">– 350 человек персонала</p><p class="en">– 350 человек персонала</p><p class="block-line block-line-bottom"></p></div><p class="ru text-normal txt-size-10">*Событие организовано совместно с компанией «Live Group»</p><p class="en text-normal txt-size-10">*Событие организовано совместно с компанией «Live Group»</p>',
      fzLeft: '19',
      right: '<p class="txt ru">Ведущей вечера выступила неповторимая Рената Литвинова.</p><p class="txt en">Ведущей вечера выступила неповторимая Рената Литвинова.</p><p class="txt ru">Кульминацией вечера стал концерт актуального классика — Земфиры.</p><p class="txt en">Кульминацией вечера стал концерт актуального классика — Земфиры.</p><p class="txt txt-size-16 ru">Прием получил награду «Лучшее оформление и дизайн мероприятия» по версии приема «Событие года»</p><p class="txt txt-size-16 en">Прием получил награду «Лучшее оформление и дизайн мероприятия» по версии приема «Событие года»</p>',
      fzRight: '21'
    }
  }
}
