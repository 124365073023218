import { useEffect, useState } from 'react'

export function useShow () {
  const [isShow, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [])
  return isShow
}
