export const hyggeParty = {
  type: 'screen',
  color: '#024479',
  eventType: {
    ru: 'Атмосферное новогоднее мероприятие',
    en: 'Atmospheric New Year event'
  },
  title: {
    ru: 'HYGGE PARTY',
    en: 'HYGGE PARTY'
  },
  link: 'hygge-party',
  bgcolor: '#0e161c',
  page: {
    image: '10-main-photo-min.jpg',
    meta: {
      date: {
        ru: 'декабрь 2019',
        en: 'december 2019'
      },
      location: {
        ru: 'Россия',
        en: 'RUSSIA'
      },
      events: [],
      tags: []
    },
    intro: {
      ru: 'В любом мероприятии главное - не сказанные там слова, а неповторимая атмосфера, которая разлита в воздухе. В этом наша философия очень созвучна со скандинавской концепцией счастья под названием Hygge!',
      en: 'В любом мероприятии главное - не сказанные там слова, а неповторимая атмосфера, которая разлита в воздухе. В этом наша философия очень созвучна со скандинавской концепцией счастья под названием Hygge!'
    },
    video: 'https://www.youtube.com/embed/s_0-Y_GwdE0?autoplay=0',
    videoImage: null,
    gradient: 'linear-gradient(150deg,#024479,#af0b87)',
    gallery: {
      small: ['1003-Small.jpg', '1006-Small.jpg'],
      large: []
    },
    gallery2: {
      small: ['1005-Small.jpg', '1004-Small.jpg'],
      large: ['1002-Small.jpg']
    },
    gallery3: {
      small: [],
      large: ['1001-Small.jpg']
    },
    content: {
      image: 'wrike.png',
      text: {
        ru: '<p class="ru"><b>В данном кейсе перед нами стояла задача</b> найти баланс между атмосферой уютного спокойствия и увлекательным событием, наполненным wow-факторами. Поэтому пространство было разделено на несколько зон притяжения внимания.</p><p class="ru">В одной из них сказки Андерсена читал главный волшебник нашего детства - Сергей Паршин. В другой прошел Kostёr Party – теплый мини-ивент с возможностью исполнения песен под гитару.</p>',
        en: '<p class="en"><b>В данном кейсе перед нами стояла задача</b> найти баланс между атмосферой уютного спокойствия и увлекательным событием, наполненным wow-факторами. Поэтому пространство было разделено на несколько зон притяжения внимания.</p><p class="en">В одной из них сказки Андерсена читал главный волшебник нашего детства - Сергей Паршин. В другой прошел Kostёr Party – теплый мини-ивент с возможностью исполнения песен под гитару.</p>',
        fz: '19'
      }
    },
    content2: {
      title: '<p class="ru">В центре расположился настоящий каток, где свое мастерство показывали профессиональные танцоры на льду.</p><p class="en">В центре расположился настоящий каток, где свое мастерство показывали профессиональные танцоры на льду.</p>',
      fzTitle: '28',
      text: '<p class="ru">Около катка работала радиобудка с ведущей и музыкой по заявкам. Атмосфера хюгге подчеркивалась в деталях: оригинальная подача угощений, множество датских рождественских воркшопов, фотозона с самоедом, живущим в офисе компании, другом всего Wrike.</p><p class="en">Около катка работала радиобудка с ведущей и музыкой по заявкам. Атмосфера хюгге подчеркивалась в деталях: оригинальная подача угощений, множество датских рождественских воркшопов, фотозона с самоедом, живущим в офисе компании, другом всего Wrike.</p>',
      fzText: '21'
    },
    content3: {
      left: '<div class="text-bold txt-size-15"><p class="ru">В результате главная мысль философии хюгге оказалась максимально проиллюстрирована: хюгге — это слово, которое не пишется, а чувствуется. А искреннее счастье -  не в вещах, а в людях, эмоциях и ощущениях.</p><p class="en">В результате главная мысль философии хюгге оказалась максимально проиллюстрирована: хюгге — это слово, которое не пишется, а чувствуется. А искреннее счастье -  не в вещах, а в людях, эмоциях и ощущениях.</p><p class="block-line block-line-bottom"></p></div>',
      fzLeft: '19',
      right: '<div class="text-normal"><p class="ru">Эмоциональной кульминацией вечера стал выезд ретро автомобиля, нагруженного подарками и управляемого снежными персонажами.</p><p class="en">Эмоциональной кульминацией вечера стал выезд ретро автомобиля, нагруженного подарками и управляемого снежными персонажами.</p></div>',
      fzRight: '19'
    }
  }
}
