
import React, { useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { Context } from '../../../context'

import './pagination.scss'

import { classNames } from '../../utils/class-names'
import { useShow } from '../../utils/animation'

export const Pagination = ({ onClickPagination }) => {
  const { hash } = useLocation()

  const { screens } = useContext(Context)
  const isShow = useShow()

  return (
    <CSSTransition
      in={isShow}
      timeout={1000}
      classNames={classNames}
      unmountOnExit
    >
      <ul className='pagination'>
        {screens.map((screen, i) => (
          <li key={i} className='pagination-item'>
            <a
              className={clsx('pagination-link', hash.replace('#', '') === screen.link && '__active')}
              href={`/${screen.link}`}
              onClick={evt => {
                evt.preventDefault()
                onClickPagination(i)
              }}
            >
              <span
                className='pagination-text'
                style={{ backgroundColor: screen.color }}
              >
                {screen.link}
              </span>
            </a>
          </li>
        ))}
      </ul>
    </CSSTransition>
  )
}
