import React from 'react'
import { CSSTransition } from 'react-transition-group'

import './buttons.scss'

import { classNames } from '../../utils/class-names'
import { useShow } from '../../utils/animation'

export const Buttons = ({ setShowForm }) => {
  const isShow = useShow()

  const handleOpenButtonClick = evt => {
    evt.preventDefault()
    setShowForm(true)
  }

  return (
    <CSSTransition
      in={isShow}
      timeout={1000}
      classNames={classNames}
      unmountOnExit
    >
      <div className='buttons'>
        <ul className='social'>
          <li className='social-item'>
            <a className='social-link __facebook' href='https://www.facebook.com/PandaAgency' rel='noopener noreferrer' target='_blank'>Facebook</a>
          </li>
          <li className='social-item'>
            <a className='social-link __instagram' href='https://www.instagram.com/pandaproject' rel='noopener noreferrer' target='_blank'>Instagram</a>
          </li>
        </ul>
        <a className='buttons-formLink' href='/' onClick={handleOpenButtonClick}>
          <span className='ru'>Узнать кто под маской</span>
          <span className='en'>Learn who’s behind the mask</span>
        </a>
      </div>
    </CSSTransition>
  )
}
