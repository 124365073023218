import React from 'react'

import './mask.scss'

export const Mask = () => {
  return (
    <section className='mask'>
      <h3 className='visually-hidden'>Про маски</h3>
      <div className='mask-container container'>
        <div className='mask-col'>
          <p className='mask-text __medium __first'>
            <span className='ru'>Вы спросите,<br />почему мы в масках?</span>
            <span className='en'>You’ll ask us&nbsp;–<br />why do we wear masks?</span>
          </p>
        </div>
        <div className='mask-col'>
          <p className='mask-text'>
            <span className='ru'>Всё просто: каждый в нашей стае — по умолчанию редкий event-зверь, которому совершенно не нужно доказывать свою неповторимость.</span>
            <span className='en'>Plain and simple: everyone in our pack – by default – a unique event-beast who doesn’t need to prove its uniqueness.</span>
          </p>
        </div>
        <div className='mask-col'>
          <b className='mask-text __slogan'>
            <span className='ru'>Мы — стая, которая уверенно идет к поставленной цели.</span>
            <span className='en'>We are a pack which is sure-footed about the aim it is pursuing.</span>
          </b>
        </div>
        <div className='mask-col'>
          <p className='mask-text __medium'>
            <span className='ru'>Постоянная жажда открытий и решений, подход к решению не от формы, а от содержания, новаторство даже в мелочах — наверное, именно это и характеризует наше мышление.</span>
            <span className='en'>Constant thirst for discoveries and new options, strategy of the content as a basis (not a form), innovations event in tiny details – perhaps this is what reflects our thinking.</span>
          </p>
          <p className='mask-text'>
            <span className='ru'>Каждый проект — это, в первую очередь, режиссерский event, создание цельного произведения, в котором есть интрига, сюжет, развязка</span>
            <span className='en'>Each project – it is, first of all – a directed event, a creation of a full-scale work, containing the suspense, the plot, the outcome.</span>
          </p>
        </div>
      </div>
    </section>
  )
}
