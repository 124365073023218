import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { Form } from '../form'
import { classNames } from '../utils/class-names'

import './contacts.scss'

export const Contacts = ({ isShow }) => {
  return (
    <CSSTransition
      in={isShow}
      timeout={500}
      classNames={classNames}
      unmountOnExit
    >
      <section className='contacts'>
        <h2 className='visually-hidden'>Контакты</h2>
        <div className='contacts-inner'>
          <div className='contacts-question'>
            <b className='contacts-questionText'>
              <span className='ru'>Узнать кто под маской</span>
              <span className='en'>Learn who’s behind the mask</span>
            </b>
          </div>
          <div className='contacts-content'>
            <p className='contacts-text'><a href='tel:+79219320923'>+7 921 932 09 23</a> / Санкт-Петербург</p>
            <p className='contacts-text'><a target='_blank' href='info@panda-project.ru'>info@panda-project.ru</a></p>
            <ul className='contacts-socialList'>
              <li className='contact-socialItem'>
                <a
                  href='whatsapp://send?text=Привет!&phone=+79219320923'
                  onClick={evt => {
                    evt.preventDefault()
                    window.open('whatsapp://send?text=Привет!&phone=+79219320923')
                  }}
                  className='contact-socialLink __whatsapp'
                >
                    whatsapp
                </a>
              </li>
              <li className='contact-socialItem'>
                <a rel='noopener noreferrer' target='_blank' href='https://www.facebook.com/PandaAgency' className='contact-socialLink __facebook'>facebook</a>
              </li>
              <li className='contact-socialItem'>
                <a rel='noopener noreferrer' target='_blank' href='https://www.instagram.com/pandaproject' className='contact-socialLink __instagram'>instagram</a>
              </li>
            </ul>
            <address className='contacts-text ru'>СПб, ул. Большая Разночинная, 14,<br />БЦ «Бизнес Депо», оф. 306</address>
            <address className='contacts-text en'>Saint Petersburg, Bolshaya Raznochinnaya st., 14,<br />Business Center «Business Depot», office # 306</address>
          </div>
        </div>
        <Form contactsPage isShowForm isEventClose={false} />
      </section>
    </CSSTransition>
  )
}
