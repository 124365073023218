export const allUpToKopeyka = {
  type: 'screen',
  color: '#683cf7',
  eventType: {
    ru: 'Встреча президентов BMW',
    en: 'BMW PRESIDENTS MEETING'
  },
  title: {
    ru: 'Всё до «копеЙки»!',
    en: 'ALL UP TO "KOPEYKA"!'
  },
  link: 'all-up-to-kopeyka',
  bgcolor: '#2c2444',
  page: {
    image: '081.jpg',
    meta: {
      date: {
        ru: 'июль 2015',
        en: 'JULY 2015'
      },
      location: {
        ru: 'Санкт-Петербург',
        en: 'SAINT PETERSBURG'
      },
      events: [],
      tags: []
    },
    intro: {
      ru: 'Обычно встречи первых лиц крупных зарубежных компаний в Петербурге проходят примерно одинаково. Наверное, такое способен представить каждый: пятизвездочный отель в центре города, Эрмитаж, премиум- ресторан русской кухни…',
      en: 'Usually meetings of top leaders of major foreign companies are held in Saint Petersburg in quite similar ways. Apparently, it’s possible for everyone ot imagine: five-star hotel in the city center, Hermitage, premium restaurant of Russian cuisine…'
    },
    video: null,
    gradient: 'linear-gradient(150deg,#683cf7,#2d61e2)',
    videoImage: '18KaYe8c1uA.jpg',
    gallery: {
      small: [],
      large: ['kLDa-tgsxeY.jpg']
    },
    gallery2: {
      small: [],
      large: ['iXjSR0qZQJk.jpg']
    },
    gallery3: null,
    content: {
      image: 'tur.png',
      imageSmall: true,
      text: {
        ru: '<p class="ru">Конечно же, это суть жанра таких мероприятий, от которого нельзя отступать. Встречая гостей из Германии — президентов концерна BMW — мы предложили им все необходимое по высшему разряду, учитывая их высокий статус и оказанное нам доверие.</p>',
        en: '<p class="en">Certainly, this is the essence of the genre of such events, which you are not able to depart from. While meeting guests from Germany – presidents of BMW group – we provided them all that is needed to a highest standard, considering their status and confidence given to us.</p>',
        fz: '19'
      }
    },
    content2: {
      title: '<p class="ru">Что может впечатлить немецкого топ- менеджера в Санкт-Петербурге сильнее, чем Эрмитаж, икра и матрешка?</p><p class="en">What could impress a German top manager visiting Saint Petersburg more than Hermitage, caviar and matryoshka?</p>',
      fzTitle: '31',
      text: '<p class="ru">Но мы бы не были собой, если бы не внесли в программу изюминку, которая — мы уверены — и стала самым ярким и незабываемым моментом! Мы предложили гостям автомобильный квест, в ходе которого президенты должны были добраться от Невского проспекта до Янтарной комнаты, выполняя задания на чек-пойнтах. Но когда они узнали, на чем именно им придется добраться до Царского села, то были поражены до глубины души — <b>их транспортом оказались «москвичи», «жигули» и «волги», выпущенные до 1990г.!</b></p><p class="en">We wouldn’t be us if we weren’t integrated a zing into the concept which became – and we’re sure about that – the brightest and unforgettable moment! We suggested a car quest to the guests – while solving it presidents had to reach the Amber room in Catherine Palace (Tsarskoye Selo) from Nevsky Prospect, fulfilling task at various checkpoints. But when they learned what shall they drive to reach Tsarskoye Selo – they were stunned – their transport was Soviet cars – “Moskvich”s, “Zhiguli”s and “Volga”s manufactured before 1990!</p>',
      fzText: '19'
    },
    content3: {
      left: '<p class="block-line bottom-underline"></p>',
      fzLeft: '19',
      right: '<p class="ru">Квест завершился экскурсией по Екатерининскому дворцу и Янтарной комнате, а также большим ужином в русском ресторане. А мы еще долго вспоминали, как трое серьезных немецких мужчины пытались совместно поймать сцепление на «копейке»!</p><p class="en">Quest finished with a tour around the Catherine Palace and Amber Room and with grand dinner in Russian restaurant. And were remembering for a while how three serious German men tried, with joint efforts, to catch the clutch driving “Kopeyka”.</p> ',
      fzRight: '19'
    }
  }
}
