import React from 'react'
import clsx from 'clsx'

import ReactHtmlParser from 'react-html-parser'

import './case.scss'
import { Link } from 'react-router-dom'

export const Case = ({ screen }) => {
  const link = `/case#${screen.type === 'promo' ? 'together-show' : screen.link}`
  return (
    <section className='case'>
      {screen.type === 'promo' &&
        <h2 className='case-promoTitle'>
          <span className='case-promoText ru'><b>Panda&nbsp;Project&nbsp;—</b> Агентство режиссерских событий</span>
          <span className='case-promoText en'><b>Panda&nbsp;Project&nbsp;—</b> Agency of directed events</span>
        </h2>}

      {screen.type === 'screen' &&
        <div className='case-info'>
          <Link to={link}>
            <p className='case-typeEvent'>
              <span className='ru'>{ReactHtmlParser(screen.eventType.ru)}</span>
              <span className='en'>{ReactHtmlParser(screen.eventType.en || screen.eventType.ru)}</span>
            </p>
            <h2 className='case-title' style={{ color: screen.color }}>
              <span className='ru'>{ReactHtmlParser(screen.title.ru)}</span>
              <span className='en'>{ReactHtmlParser(screen.title.en || screen.title.ru)}</span>
            </h2>
          </Link>
          <Link className='case-linkMore' to={link} style={{ color: screen.color, borderColor: screen.color }}>
            <span className='case-linkMoreBg' style={{ backgroundColor: screen.color }} />
            <span className='case-linkMoreText ru'>Подробнее...</span>
            <span className='case-linkMoreText en'>More details...</span>
          </Link>
        </div>}
      <Link to={link} className='case-imageWrap'>
        <picture>
          <source srcSet={`${process.env.PUBLIC_URL}/images/cases/${screen.image || screen.link}.webp`} type='image/webp' />
          <img className={clsx('case-image', screen.type === 'promo' && '__promo')} src={`${process.env.PUBLIC_URL}/images/cases/${screen.image || screen.link}.png`} alt='Человек в костюме панды' />
        </picture>
      </Link>
    </section>
  )
}
