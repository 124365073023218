import React from 'react'
import clsx from 'clsx'

import { clientsData } from './clients-data'

import './clients.scss'

export const Clients = () => {
  return (
    <section className='clients'>
      <div className='container'>
        <h3 className='clients-subtitle'>
          <span className='ru'>Клиенты</span>
          <span className='en'>Customers</span>
        </h3>
        <ul className='clients-list'>
          {clientsData.map((client, i) => (
            <li key={i} className={clsx('clients-item', client.imgActive === 'x5.png' && '__big')}>
              <a className='clients-link' href={client.link} rel='noopener noreferrer' target='_blank'>
                <img className='clients-img' src={`/images/clients/${client.img}`} alt='' />
                <img className='clients-img __active' src={`/images/clients/${client.imgActive}`} alt='' />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
