import React, { useState, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { Context } from '../../context'

import './header.scss'

import { classNames } from '../utils/class-names'
import { useLang } from '../utils/lang'
import { useShow } from '../utils/animation'

const LANG_RU = 'ru'
const LANG_EN = 'en'

export const Header = () => {
  const isShow = useShow()

  const { global } = useContext(Context)

  const [open, setOpen] = useState(false)

  const { pathname } = useLocation()

  const [lang, setLang] = useLang()

  const handleLangClick = e => {
    e.preventDefault()
    setLang(e.target.textContent.trim() || LANG_RU)
  }

  const handleMenuItemClick = () => {
    setOpen(prev => !prev)
  }

  return (
    <CSSTransition
      in={isShow}
      timeout={800}
      classNames={classNames}
      unmountOnExit
    >
      <header className={clsx(
        'header',
        open && '__open',
        pathname === '/case' && '__case',
        pathname === '/experience' && '__experience',
        pathname === '/contacts' && '__contacts')}
      >
        <div className='header-logo'>
          <Link
            className='header-logoLink'
            to='/cases#promo'
            onClick={handleMenuItemClick}
          >
            <img
              className='header-logoImage' src={`${process.env.PUBLIC_URL}/images/logo-dark.png`}
              alt='Логотип Pande Project' width='48' height='48'
            />
            <img
              className='header-logoImage __white' src={`${process.env.PUBLIC_URL}/images/logo-white.png`}
              alt='Логотип Pande Project' width='48' height='48'
            />
          </Link>
          <button
            className={clsx('header-lang', lang === LANG_RU && '__active')}
            onClick={handleLangClick}
          >{LANG_RU}
          </button>
          <button
            className={clsx('header-lang', lang === LANG_EN && '__active')}
            onClick={handleLangClick}
          >{LANG_EN}
          </button>
        </div>

        <button className='header-toggle' onClick={() => setOpen(open => !open)}>
          Открыть/Закрыть меню
          <span className='header-toggleClose' />
        </button>

        <nav className='nav'>
          <ul className='nav-list'>
            <li className='nav-item'>
              <NavLink className='nav-link' activeClassName='__active' to={`/cases#${global.caseLink}`} onClick={handleMenuItemClick}>
                <span className='ru'>Кейсы</span>
                <span className='en'>Projects</span>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' activeClassName='__active' to='/experience' onClick={handleMenuItemClick}>
                <span className='ru'>Опыт</span>
                <span className='en'>Experience</span>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' activeClassName='__active' to='/contacts' onClick={handleMenuItemClick}>
                <span className='ru'>Контакты</span>
                <span className='en'>Contacts</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
    </CSSTransition>
  )
}
