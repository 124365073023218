import React from 'react'
import ReactDOM from 'react-dom'

import 'normalize.css'
import './scss/main.scss'

import App from './components/app'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
