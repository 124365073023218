import React from 'react'

import './form-button.scss'

export const FormButton = ({ text, type, name }) => {
  return (
    <label className='formButton'>
      <input className='formButton-input' type={type} value={text} name={name} />
      <span className='formButton-text'>{text}</span>
    </label>
  )
}
