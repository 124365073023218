export const fromTheHeart = {
  type: 'screen',
  color: '#21c557',
  eventType: {
    ru: 'Благотворительный проект',
    en: 'CHARITABLE PROJECT'
  },
  title: {
    ru: 'public talk от сердца',
    en: 'PUBLIC TALK FROM THE HEART'
  },
  link: 'from-the-heart',
  bgcolor: '#1d3124',
  page: {
    image: '051.jpg',
    meta: {
      date: {
        ru: 'апрель 2016',
        en: 'APRIL 2016'
      },
      location: {
        ru: 'Санкт-Петербург',
        en: 'SAINT PETERSBURG'
      },
      events: [],
      tags: ['#модасосмыслом']
    },
    intro: {
      ru: 'Уже несколько лет бренд <b>ZARINA</b> активно занимается благотворительностью и поддерживает социально значимые проекты. Именно поэтому ключевой месседж бренда — «Мода со смыслом»! Ведь это правда — помогая другим, мы помогаем себе.',
      en: 'ZARINA clothing brand is actively contribute to charity and support social projects already for several years. That is why the key message of the brand is “Meaningful fashion”! Indeed it is true that if we help others, we help ourselves'
    },
    video: 'https://www.youtube.com/embed/6iemDc5H9cI?autoplay=0',
    videoImage: null,
    gradient: 'linear-gradient(150deg,#21c557,#1da0a1)',
    gallery: {
      small: [],
      large: ['KRYL1016.jpg', 'KRYL0920.jpg']
    },
    gallery2: {
      small: [],
      large: ['KRYL0923.jpg']
    },
    gallery3: null,
    content: {
      image: 'clothes.jpg',
      text: {
        ru: '<p class="ru"><b>Задачей нашего агентства стала организация пресс-показа и презентации коллекции с участием Натальи Водяновой.</b></p><p class="ru">Но изначально это был не наш проект…<br>Агентство отказалось от клиента, и ZARINA пришла к нам за три недели до запланированного мероприятия. Мы понимали, что идем на риск, но все же решили взять проект. За 10 дней до старта участие Натальи Водяновой оказалось под вопросом — она была на восьмом месяце беременности и находилась в Париже; все зависело от ее состояния.</p><p class="ru">Мы решили ничего не скрывать и говорить своим партнерам и прессе правду о сложившейся ситуации. И это сработало!</p>',
        en: '<p class="en"><b>Brief for our agency was to arrange a press-show and collections presentation featuring Natalia Vodyanova.</b></p><p class="en">But in the very beginning it wasn’t our project… Previous agency refused to cooperate and ZARINA addressed to us three weeks before the planned event. We understood that we take a risk but we agreed to take on the project. 10 days before the beginning participation of Natalia Vodyanova became in question – she was eight months pregnant and was in Paris; everything depended on her condition.</p><p class="en">We decided not to hide anything and frankly talk to our partners and mass media, informing them about true situation. And it worked!</p>',
        fz: '19'
      }
    },
    content2: {
      title: '<p class="ru">Почему важны добрые мероприятия, как организовать их в условиях жесткого дефицита времени и чем прекрасна Наталья Водянова?</p><p class="en">Why events of kindness are important, how to arrange them in the situation of tight schedule and why Natalia Vodyanova is great?</p>',
      fzTitle: '31',
      text: '<p class="ru">За короткий срок откликнулись многие, и большую часть ивент-составляющей нам удалось сделать бесплатно на партнерских условиях, а представители СМИ готовы были не только к отмене самого мероприятия, но и к тому, чтобы прождать на площадке три часа, так как Наталья все же опаздывала, задержавшись по состоянию здоровья. И это в пятницу, 29 апреля, когда вся Москва стояла на пороге майских праздников!</p><p class="en">During very shorth term, lots of people responded and major part of event was created off-charge on terms of partnership and media representatives were prepared to possible event cancellation as well as to wait three hours at the venue, as Natalia was late due to her health condition. And that was Friday, April 29th when whole Moscow is on the edge of May holidays!</p><p class="ru">Почему мы взялись за этот проект? Все просто: каждый в нашей стае знает, как важно помогать тем, кто остро нуждается в помощи, и совершать добрые дела.</p><p class="en">Why have we took this project? Simple as that everyone in our pack knows how important it is help those who are in a desperate need of help and to commit good deeds.</p>',
      fzText: '19'
    },
    content3: {
      left: '<p class="ru">Алина Косенко</p><p class="en">Alina Kosenko</p><p class="ru text-uppercase txt-size-10">pr-специалист бренда «zarina»</p><p class="en text-uppercase txt-size-10">PR-SPECIALIST OF “ZARINA” BRAND</p><p class="block-line bottom-underline"></p>',
      fzLeft: '19',
      right: '<p class="ru">«На мой взгляд, команду Panda Project наиболее точно характеризует понятие «смелость»: они не боятся реализовывать любые идеи. Кроме того (как получилось и с нашим проектом), <b>Panda Project</b> объективно осознает все риски, с блеском выходя даже из сложных ситуаций. Это дорогого стоит!»</p><p class="en">"In my opinion, the word "courage" pictures the team of Panda Project in the most exact way: they are not afraid to materialize any ideas. Moreover (and that was with our project), Panda Project adequately consider all the risks, brilliantly resolving even complicated situations. That’s worth its weight in gold!"</p>',
      fzRight: '19'
    }
  }
}
