export const semcity = {
  type: 'screen',
  color: '#ef6a3c',
  eventType: {
    ru: 'Корпоративный фестиваль',
    en: 'corporate festival'
  },
  title: {
    ru: 'SEMCity: <br>District 11'
  },
  link: 'semcity',
  bgcolor: '#422c24',
  page: {
    image: '03-main-photo-min.jpg',
    meta: {
      date: {
        ru: 'АВГУСТ 2019',
        en: 'AUGUST 2019'
      },
      location: {
        ru: 'Россия, Сантк-Петербург',
        en: 'RUSSIA, SAINT PETERSBURG'
      },
      events: [],
      tags: ['#SEMRUSH_PEOPLE']
    },
    intro: {
      ru: 'Для события, которое наполнено идеями и смыслом, не существует границ. Чтобы осуществить задуманное, можно даже построить целый город! ',
      en: 'Для события, которое наполнено идеями и смыслом, не существует границ. Чтобы осуществить задуманное, можно даже построить целый город! '
    },
    video: 'https://www.youtube.com/embed/r5RCketwVHY?autoplay=0',
    videoImage: null,
    gradient: 'linear-gradient(150deg,#ef6a3c,#e2103d)',
    gallery: {
      small: [],
      large: ['0307-Small-min.jpg']
    },
    gallery2: {
      small: ['0303-Small-min.jpg', '0302-Small-min.jpg'],
      large: ['0308-Small-min.jpg']
    },
    gallery3: {
      small: ['0309-Small-min.jpg', '0301-Small-min.jpg'],
      large: ['0304-Small-min.jpg']
    },
    content: {
      image: 'semrush.png',
      text: {
        ru: '<p class="ru"><b>SEMrush</b> — прогрессивная IT-компания, которая знает: хочешь изменить мир — начни с себя. А если хочешь жить в идеальном районе или даже городе? Создай его сам и установи там свои правила! Именно поэтому мы предложили концепцию «SEMCity District 11». </p><p class="ru">Событие виделось как уличная вечеринка в окружении небоскребов. Дело осталось за малым — найти в Петербурге небоскребы… И мы их нашли: там, где еще не ступала нога ивента! </p>',
        en: '<p class="en"><b>SEMrush</b> — прогрессивная IT-компания, которая знает: хочешь изменить мир — начни с себя. А если хочешь жить в идеальном районе или даже городе? Создай его сам и установи там свои правила! Именно поэтому мы предложили концепцию «SEMCity District 11».</p><p class="en">Событие виделось как уличная вечеринка в окружении небоскребов. Дело осталось за малым — найти в Петербурге небоскребы… И мы их нашли: там, где еще не ступала нога ивента! </p>',
        fz: '19'
      }
    },
    content2: {
      title: '<p class="ru">Первая — уличная — часть события прошла во дворе между отелем «Хилтон» и зданием «Экспофорума».</p><p class="en">Первая — уличная — часть события прошла во дворе между отелем «Хилтон» и зданием «Экспофорума».</p>',
      fzTitle: '31',
      text: '<p class="ru"><b>Это удивительный зеленый оазис, окруженный черными блестящими стеклянными стенами зданий. Он просто создан, чтобы перенести туда атмосферу даунтауна Лос-Анджелеса!</b></p><p class="en"><b>Это удивительный зеленый оазис, окруженный черными блестящими стеклянными стенами зданий. Он просто создан, чтобы перенести туда атмосферу даунтауна Лос-Анджелеса!</b></p><p class="ru">Фактура наполнилась фишками: от промо на стенах офиса в стиле Бэнкси и авторских дорожных знаков до крутейшей гаражной вечеринки. Ее вершиной стал концерт невероятного Ивана Дорна в сопровождении уникального мультимедийного лазерного шоу.</p><p class="en">Фактура наполнилась фишками: от промо на стенах офиса в стиле Бэнкси и авторских дорожных знаков до крутейшей гаражной вечеринки. Ее вершиной стал концерт невероятного Ивана Дорна в сопровождении уникального мультимедийного лазерного шоу.</p>',
      fzText: '18'
    },
    content3: {
      left: '<div class="text-bold"><p class="ru">– 6 локаций</p><p class="en">– 6 локаций</p><p class="ru">– 400 гостей</p><p class="en">– 400 гостей</p><p class="ru">– 2,5 дня монтажа</p><p class="en">– 2,5 дня монтажа</p><p class="ru">– 110 человек персонала</p><p class="en">– 110 человек персонала</p><p class="block-line block-line-bottom"></p></div>',
      fzLeft: '19',
      right: '<div class="text-normal"><p class="ru">В итоге «SEMCity District 11: Свой город – свои правила!» стал местом беспредельного драйва и энергии, где каждый мог позволить себе главное — быть собой среди своих.</p><p class="en">В итоге «SEMCity District 11: Свой город – свои правила!» стал местом беспредельного драйва и энергии, где каждый мог позволить себе главное — быть собой среди своих.</p></div>',
      fzRight: '19'
    }
  }
}
