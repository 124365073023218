import React from 'react'
import clsx from 'clsx'

export const ExpertiseList = ({ list, current, setCurrent }) => (
  <ul className='expertise-list'>
    {list.map((expertise, i) => (
      <li key={i} className='expertise-item'>
        <button
          className={clsx('expertise-itemButton', current === expertise.en && '__active')}
          onMouseEnter={() => {
            setCurrent(expertise.en)
          }}
        >
          <span className='ru'>{expertise.ru}</span>
          <span className='en'>{expertise.en}</span>
        </button>
      </li>
    ))}
  </ul>
)
