import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { classNames } from '../utils/class-names'
import { Clients } from './clients'

import './experience.scss'
import { Expertise } from './expertise'
import { Mask } from './mask'
import { Statistics } from './statistics'
import { Video } from './video'

export const Experience = ({ isShow }) => {
  return (
    <CSSTransition
      in={isShow}
      timeout={500}
      classNames={classNames}
      unmountOnExit
    >
      <div className='exp'>
        <h2 className='visually-hidden'>Опыт</h2>
        <Statistics />
        <Video />
        <Clients />
        <Expertise />
        <Mask />
      </div>
    </CSSTransition>
  )
}
