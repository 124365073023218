import React, { useState } from 'react'
import clsx from 'clsx'
import { ExpertiseList } from './expertiseList'

import { expertisesData } from './expertisesData'

import './expertise.scss'

export const Expertise = () => {
  const [current, setCurrent] = useState(expertisesData[0].en)

  return (
    <section className='expertise'>
      <div className='expertise-container container'>
        <div className='expertise-col'>
          <h3 className='expertise-subtitle'>
            <span className='ru'>Наша экспертиза</span>
            <span className='en'>Our expertise</span>
          </h3>
          <ul className='expertise-listText'>
            {expertisesData.map((expertise, i) => (
              <li key={i} className={clsx('expertise-itemText', current === expertise.en && '__active')}>
                <span className='ru'>{expertise.text.ru}</span>
                <span className='en'>{expertise.text.en}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className='expertise-col __small'>
          <ExpertiseList list={expertisesData.slice(0, expertisesData.length / 2)} current={current} setCurrent={setCurrent} />
        </div>
        <div className='expertise-col __small'>
          <ExpertiseList list={expertisesData.slice(expertisesData.length / 2, expertisesData.length)} current={current} setCurrent={setCurrent} />
        </div>
      </div>
    </section>
  )
}
